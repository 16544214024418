// CreateLCForm.js
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, DatePicker, InputNumber, Steps, Row, Col, message } from 'antd';
import moment from 'moment';
import { getBankAccountSetups } from '../../hooks/useBankAccountSetup';
const { Step } = Steps;
const { Option } = Select;

const paymentMethodOptions = [
  { label: 'Deferred Local', value: 'DL' },
  { label: 'Sight Local', value: 'SL' },
  { label: 'Deferred Foreign', value: 'DF' },
  { label: 'Sight Foreign', value: 'SF' },
];

const companyOptions = ['Zoman', 'RK'];

const lcBankFacilityOptions = [
  { label: 'LC Bank Facility ALAhli', value: 'LC Bank Facility ALAhli' },
  { label: 'LC Bank Facility Samba', value: 'LC Bank Facility Samba' },
  { label: 'LC Bank Facility SABB', value: 'LC Bank Facility SABB' },
  { label: 'LC Bank Facility Riyad Bank', value: 'LC Bank Facility Riyad Bank' },
  { label: 'LC Bank Facility Arab Bank', value: 'LC Bank Facility Arab Bank' },
  { label: 'LC Bank Facility Saudi Fransi', value: 'LC Bank Facility Saudi Fransi' },
  { label: 'LC Bank Facility AlRajhi', value: 'LC Bank Facility AlRajhi' },
  { label: 'LC Bank Facility AlBilad', value: 'LC Bank Facility AlBilad' },
  { label: 'LC Bank Facility Alinma', value: 'LC Bank Facility Alinma' },
];

const CreateLCForm = ({ onSubmit, loading }) => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [marginAmount, setMarginAmount] = useState(0);
  const [vatAmount, setVatAmount] = useState(0);
  const [bankAccountSetups, setBankAccountSetups] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);

  useEffect(() => {
    const fetchBankAccountSetups = async () => {
      try {
        const setups = await getBankAccountSetups();
        setBankAccountSetups(setups);
      } catch (error) {
        console.error('Error fetching bank account setups:', error);
        message.error('Failed to load bank account setups');
      }
    };
    fetchBankAccountSetups();
  }, []);

  const next = () => {
    form.validateFields().then(values => {
      setFormData({ ...formData, ...values });
      setCurrentStep(currentStep + 1);
    }).catch(info => {
      console.log('Validate Failed:', info);
    });
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleFinish = (values) => {
    const combinedValues = {
      ...formData,
      ...values,
      marginAmount: Number(marginAmount.toFixed(2)),
      issuedDate: formData.issuedDate ? formData.issuedDate.toISOString() : undefined,
      expiryDate: values.expiryDate ? values.expiryDate.toISOString() : undefined,
      type: 'LC'
    };
    const pendingRequests = JSON.parse(localStorage.getItem('pendingRequests')) || [];
    localStorage.setItem('pendingRequests', JSON.stringify([...pendingRequests, combinedValues]));
    window.dispatchEvent(new Event('storage'));
  
    message.info('Your request has been added to pending requests. Please check the notifications.');
  };
  

  const calculateMarginAmount = (amount, marginPercent) => {
    if (amount && marginPercent) {
      const margin = (amount * marginPercent) / 100;
      setMarginAmount(Number(margin.toFixed(2)));
    } else {
      setMarginAmount(0);
    }
  };

  const calculateVAT = (bankCharges) => {
    if (bankCharges) {
      const vat = Number((bankCharges * 0.15).toFixed(2));
      setVatAmount(vat);
      form.setFieldsValue({ VAT: vat });
    } else {
      setVatAmount(0);
      form.setFieldsValue({ VAT: 0 });
    }
  };

  const steps = [
    {
      title: 'Basic Info',
      content: (
        <>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="company" label="Company" rules={[{ required: true }]}>
                <Select>
                  {companyOptions.map(company => (
                    <Option key={company} value={company}>{company}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="LCNumber" label="LC Number" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="vendor" label="Vendor" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="bank" label="Bank" rules={[{ required: true }]}>
                <Select onChange={(value) => setSelectedBank(value)}>
                  {[...new Set(bankAccountSetups.map(setup => setup.bankGroup))].map(bank => (
                    <Option key={bank} value={bank}>{bank}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="bankID" label="Bank Account/ID" rules={[{ required: true }]}>
                <Select disabled={!selectedBank}>
                  {bankAccountSetups
                    .filter(setup => setup.bankGroup === selectedBank)
                    .map(setup => (
                      <Option key={setup.objectId} value={setup.bankIdAccount}>
                        {setup.bankIdAccount}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="issuedDate" label="Issued Date" rules={[{ required: true }]}>
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="paymentMethod" label="Payment Method" rules={[{ required: true }]}>
                <Select>
                  {paymentMethodOptions.map(option => (
                    <Option key={option.value} value={option.value}>{option.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="LCBankReference" label="LC Bank Reference" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
            <Col span={12}>
              <Form.Item name="LCBankFacility" label="LC Bank Facility" rules={[{ required: true }]}>
                <Select>
                  {lcBankFacilityOptions.map(option => (
                    <Option key={option.value} value={option.value}>{option.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
        </>
      ),
    },
    {
      title: 'Details',
      content: (
        <>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="amount" label="Amount" rules={[{ required: true }]}>
                <InputNumber 
                  style={{ width: '100%' }}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  onChange={(value) => calculateMarginAmount(value, form.getFieldValue('marginPercent'))} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="expiryDate" label="Expiry Date" rules={[{ required: true }]}>
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="authorizedRepresentative" label="Authorized Representative">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="beneficiary" label="Beneficiary">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="marginPercent" label="Margin %" rules={[{ required: true }]}>
                <InputNumber 
                  style={{ width: '100%' }}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}                
                  onChange={(value) => calculateMarginAmount(form.getFieldValue('amount'), value)} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Margin Amount">
                <InputNumber
                  style={{ width: '100%' }}
                  value={marginAmount}
                  disabled
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="bankCharges" label="Bank Charges" rules={[{ required: true }]}>
                <InputNumber 
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}                
                  style={{ width: '100%' }}
                  onChange={calculateVAT}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="VAT" label="VAT" rules={[{ required: true }]}>
                <InputNumber 
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}                
                  style={{ width: '100%' }}
                  disabled
                  value={vatAmount}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="terms" label="Terms">
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  return (
    <>
      <Steps current={currentStep}>
        {steps.map((item, index) => (
          <Step key={index} title={item.title} />
        ))}
      </Steps>
      <Form form={form} layout="vertical" onFinish={handleFinish} style={{ marginTop: 20 }}>
        {steps[currentStep].content}
        <div className="steps-action" style={{ marginTop: 20 }}>
          {currentStep > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Previous
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button type="primary" htmlType="submit" loading={loading}>
              {loading ? 'Creating...' : 'Submit'}
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

export default CreateLCForm;
