// components/BankAccountSetup/BankAccountSetupFields.js
import React from 'react';
import { Form, Input, Checkbox, Row, Col, Card } from 'antd';

const BankAccountSetupFields = ({ data, isEditing, onFieldChange }) => {
  const handleFieldChange = (field, value) => {
    onFieldChange(field, value);
  };

  const renderField = (label, value, type, field) => {
    const displayValue = value || 'N/A';

    if (type === 'checkbox') {
      return isEditing ? (
        <Checkbox
          checked={value}
          onChange={(e) => handleFieldChange(field, e.target.checked)}
        >
          Active
        </Checkbox>
      ) : (
        value ? 'Active' : 'Inactive'
      );
    }

    return isEditing ? (
      <Input
        defaultValue={value}
        onChange={(e) => handleFieldChange(field, e.target.value)}
        style={{ width: '100%' }}
      />
    ) : (
      displayValue
    );
  };

  return (
    <Card bordered={false} style={{ boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', marginBottom: '24px' }}>
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item 
              label="Bank Code"
              labelCol={{ style: { fontWeight: 'bold' } }}
            >
              {renderField('Bank Code', data.bankCode, 'text', 'bankCode')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label="Bank ID/Account"
              labelCol={{ style: { fontWeight: 'bold' } }}
            >
              {renderField('Bank ID/Account', data.bankIdAccount, 'text', 'bankIdAccount')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item 
              label="Bank Group"
              labelCol={{ style: { fontWeight: 'bold' } }}
            >
              {renderField('Bank Group', data.bankGroup, 'text', 'bankGroup')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label="Ledger Account"
              labelCol={{ style: { fontWeight: 'bold' } }}
            >
              {renderField('Ledger Account', data.ledgerAccount, 'text', 'ledgerAccount')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item 
              label="Account Status"
              labelCol={{ style: { fontWeight: 'bold' } }}
            >
              {renderField('Account Status', data.accountStatus, 'checkbox', 'accountStatus')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label="Bank Charge Account"
              labelCol={{ style: { fontWeight: 'bold' } }}
            >
              {renderField('Bank Charge Account', data.bankChargeAccount, 'text', 'bankChargeAccount')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item 
              label="Finance Expense Account"
              labelCol={{ style: { fontWeight: 'bold' } }}
            >
              {renderField('Finance Expense Account', data.financeExpenseAccount, 'text', 'financeExpenseAccount')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label="LC Bank Facility"
              labelCol={{ style: { fontWeight: 'bold' } }}
            >
              {renderField('LC Bank Facility', data.lcBankFacility, 'text', 'lcBankFacility')}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default BankAccountSetupFields;
