import React, { useState } from 'react';
import { InputNumber, DatePicker, Input } from 'antd';
import moment from 'moment';

const LGModalContent = ({
  actionType,
  newAmount,
  setNewAmount,
  newDate,
  setNewDate,
  data,
  bankCharges,
  setBankCharges
}) => {
  const [dateSelected, setDateSelected] = useState(false);
  const [userEnteredAmount, setUserEnteredAmount] = useState(0);

  const handleAmountChange = (value) => {
    setUserEnteredAmount(value);
    if (actionType === 'increaseAmount') {
      setNewAmount(value);
    } else if (actionType === 'reduceAmount') {
      setNewAmount(data.amount - value);
    }
  };

  const renderContent = () => {
    switch (actionType) {
      case 'increaseAmount':
      case 'reduceAmount':
        return (
          <>
            <p>Current Amount: {data.amount}</p>
            <InputNumber
              min={0}
              defaultValue={newAmount}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              onChange={handleAmountChange}
              style={{ width: '100%', marginBottom: '16px' }}
              placeholder='Enter value'
            />
            {userEnteredAmount > 0 && (
              <p>
                New Total Amount will be: {actionType === 'increaseAmount' 
                  ? data.amount + userEnteredAmount 
                  : data.amount - userEnteredAmount}
              </p>
            )}
            {actionType === 'increaseAmount' && (
              <InputNumber
                min={0}
                placeholder="Bank Charges"
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                value={bankCharges}
                onChange={setBankCharges}
                style={{ width: '100%' }}
              />
            )}
          </>
        );
      case 'extendDate':
      case 'renewLG':
        return (
          <>
            <DatePicker
              onChange={(date) => {
                setNewDate(date);
                setDateSelected(true);
              }}
              style={{ width: '100%', marginBottom: '16px' }}
            />
            {actionType === 'extendDate' && dateSelected && (
              <InputNumber
                min={0}
                placeholder="Bank Charges"
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                value={bankCharges}
                onChange={setBankCharges}
                style={{ width: '100%' }}
              />
            )}
          </>
        );
      case 'updateBeneficiary':
        return (
          <Input
            defaultValue={data.beneficiary || ''}
            onChange={(e) => setNewDate(e.target.value)}
            style={{ width: '100%' }}
          />
        );
      case 'amendLGTerms':
        return (
          <Input.TextArea
            defaultValue={data.terms || ''}
            onChange={(e) => setNewDate(e.target.value)}
            style={{ width: '100%' }}
          />
        );
      case 'transferLG':
        // Implement transfer LG specific logic here
        return null;
      case 'releaseLG':
        // Implement release LG specific logic here
        return null;
      case 'cancel':
        return <p>Are you sure you want to cancel this Letter of Guarantee?</p>;
      default:
        return null;
    }
  };

  return (
    <div>
      {renderContent()}
    </div>
  );
};

export default LGModalContent;
