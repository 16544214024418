import React from 'react';
import { Form, Input, Button } from 'antd';

const EditLoanAccountingSetupForm = ({ onSubmit, loading, initialValues }) => {
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    await onSubmit(values);
    form.resetFields();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <Form.Item
        name="oracleAccount"
        label="Oracle Account"
        rules={[{ required: true, message: 'Please enter Oracle Account' }]}
      >
        <Input placeholder="Enter Oracle Account" />
      </Form.Item>

      <Form.Item
        name="ledgerAccount"
        label="Ledger Account"
        rules={[{ required: true, message: 'Please enter Ledger Account' }]}
      >
        <Input placeholder="Enter Ledger Account" />
      </Form.Item>

      <Form.Item
        name="bankorsField"
        label="Bankors Field"
        rules={[{ required: true, message: 'Please enter Bankors Field' }]}
      >
        <Input placeholder="Enter Bankors Field" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Edit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditLoanAccountingSetupForm;
