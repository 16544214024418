import React from 'react';
import { Form, Input, Button, Row, Col, message } from 'antd';

const CreateProjectSegmentSetupForm = ({ onSubmit, loading }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    try {
      onSubmit(values);
      form.resetFields();
    } catch (error) {
      message.error('Failed to submit form: ' + error.message);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="segmentValue" label="Segment Value" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="description" label="Description" rules={[{ required: true }]}>
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateProjectSegmentSetupForm;
