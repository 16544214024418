import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, message, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getBankFacilities, useCreateBankFacility } from '../../hooks/useBankFacility';
import CreateBankFacilityForm from '../../components/BankFacility/CreateBankFacilityForm';

const BankFacilityPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { create, loading: createLoading } = useCreateBankFacility();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getBankFacilities();
      setData(result);
    } catch (error) {
      console.error('Error fetching bank facilities:', error);
      message.error('Failed to fetch bank facilities');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCreate = async (values) => {
    try {
      await create(values);
      message.success('Bank Facility created successfully');
      setIsModalVisible(false);
      fetchData();  // Refresh the data
    } catch (error) {
      console.error('Error creating bank facility:', error);
      message.error('Failed to create bank facility');
    }
  };

  const handleViewDetails = (record) => {
    navigate(`/bank-facility/${record.objectId}`);
  };

  const columns = [
    {
      title: 'Facility Description',
      dataIndex: 'facilityDescription',
      key: 'facilityDescription',
      sorter: (a, b) => a.facilityDescription.localeCompare(b.facilityDescription),
    },
    {
      title: 'Facility Number',
      dataIndex: 'facilityNumber',
      key: 'facilityNumber',
      sorter: (a, b) => a.facilityNumber.localeCompare(b.facilityNumber),
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      sorter: (a, b) => new Date(a.expiryDate) - new Date(b.expiryDate),
      render: (text) => moment(text).format('YYYY-MM-DD'),
    },
    {
      title: 'Bank',
      dataIndex: 'bank',
      key: 'bank',
      sorter: (a, b) => a.bank.localeCompare(b.bank),
    },
    {
      title: 'Bank Account/ID',
      dataIndex: 'bankAccountId',
      key: 'bankAccountId',
      sorter: (a, b) => a.bankAccountId.localeCompare(b.bankAccountId),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => handleViewDetails(record)}>View Details</Button>
        </Space>
      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={{ padding: '24px', paddingTop: '75px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <h2>Bank Facilities</h2>
        <Button type="primary" onClick={showModal}>
          Create New Facility
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey="objectId"
        pagination={{ pageSize: 10 }}
        style={{ marginBottom: '16px' }}
      />
      <Modal
        title="Create New Bank Facility"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <CreateBankFacilityForm onSubmit={handleCreate} loading={createLoading} />
      </Modal>
    </div>
  );
};

export default BankFacilityPage;
