import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, message, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getProjectSegmentSetups, useCreateProjectSegmentSetup } from '../../hooks/useProjectSegmentSetup';
import CreateProjectSegmentSetupForm from '../../components/ProjectSegmentSetup/CreateProjectSegmentSetupForm';

const ProjectSegmentSetupPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { create, loading: createLoading } = useCreateProjectSegmentSetup();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getProjectSegmentSetups();
      setData(result);
    } catch (error) {
      console.error('Error fetching project segment setups:', error);
      message.error('Failed to fetch project segment setups');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCreate = async (values) => {
    try {
      await create(values);
      message.success('Project Segment Setup created successfully');
      setIsModalVisible(false);
      fetchData();
    } catch (error) {
      console.error('Error creating project segment setup:', error);
      message.error('Failed to create project segment setup');
    }
  };

  const handleViewDetails = (record) => {
    navigate(`/setup/project-segment-setup/${record.objectId}`);
  };

const columns = [
  {
    title: 'Segment Value',
    dataIndex: 'segmentValue',
    key: 'segmentValue',
    sorter: (a, b) => a.segmentValue.localeCompare(b.segmentValue),
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (text, record) => (
      <Space size="middle">
        <Button type="link" onClick={() => handleViewDetails(record)}>
          View Details
        </Button>
      </Space>
    ),
  },
];


  return (
    <div style={{ padding: '24px', paddingTop: '75px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <h2>Project Segment Setup</h2>
        <Button type="primary" onClick={() => setIsModalVisible(true)}>
          Create New Segment
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey="objectId"
        pagination={{ pageSize: 10 }}
      />
      <Modal
        title="Create New Project Segment"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <CreateProjectSegmentSetupForm onSubmit={handleCreate} loading={createLoading} />
      </Modal>
    </div>
  );
};

export default ProjectSegmentSetupPage;
