export { default as CurrencySelect } from '../components/common/CurrencySelect';


export const currencyOptions = [
  { value: 'SAR', label: 'Saudi Riyal (SAR)' },
  { value: 'EGP', label: 'Egyptian Pound (EGP)' },
  { value: 'USD', label: 'US Dollar (USD)' },
  { value: 'EUR', label: 'Euro (EUR)' },
  { value: 'GBP', label: 'British Pound (GBP)' },
  { value: 'JPY', label: 'Japanese Yen (JPY)' },
  { value: 'CHF', label: 'Swiss Franc (CHF)' },
  { value: 'AED', label: 'UAE Dirham (AED)' },
  { value: 'CAD', label: 'Canadian Dollar (CAD)' },
  { value: 'AUD', label: 'Australian Dollar (AUD)' },
  { value: 'CNY', label: 'Chinese Yuan (CNY)' },
  { value: 'INR', label: 'Indian Rupee (INR)' },
  { value: 'BRL', label: 'Brazilian Real (BRL)' },
  { value: 'RUB', label: 'Russian Ruble (RUB)' },
  { value: 'KRW', label: 'South Korean Won (KRW)' },
  { value: 'SGD', label: 'Singapore Dollar (SGD)' }
];
