import axios from "axios";
import React from "react";
import { getApiUrlAndHeaders } from "../utils/api";
import useAsync from "./useAsync";

// Fetch all bank facilities system code setups
export const getBankFacilitiesSystemCodeSetups = async () => {
  const { url, headers } = getApiUrlAndHeaders("/classes/BankFacilitiesSystemCodeSetup");
  const response = await axios.get(url, { headers });
  return response.data.results;
};

// Hook to fetch a single bank facilities system code setup by ID
export function useGetBankFacilitiesSystemCodeSetup(id) {
  const async = useAsync();

  const getBankFacilitiesSystemCodeSetup = async () => {
    const { url, headers } = getApiUrlAndHeaders("/classes/BankFacilitiesSystemCodeSetup/" + id);
    const response = await axios.get(url, { headers });
    return response.data;
  };

  const revalidate = async () => {
    const setup = await getBankFacilitiesSystemCodeSetup(id);
    async.setData(setup);
  };

  React.useEffect(() => {
    async.run(getBankFacilitiesSystemCodeSetup());
  }, [id]);

  return { ...async, revalidate };
}

// Hook to create a new bank facilities system code setup
export function useCreateBankFacilitiesSystemCodeSetup() {
  const { run, ...rest } = useAsync();

  const create = async (data) => {
    try {
      const { url, headers } = getApiUrlAndHeaders("/classes/BankFacilitiesSystemCodeSetup");
      const response = await run(axios.post(url, data, { headers }));
      return response.data.objectId;
    } catch (error) {
      console.error('Error in createBankFacilitiesSystemCodeSetup:', error);
      throw error;
    }
  };

  return {
    create,
    ...rest,
  };
}

// Hook to update an existing bank facilities system code setup
export function useUpdateBankFacilitiesSystemCodeSetup() {
  const { run, ...rest } = useAsync();

  const update = async (id, data) => {
    const { url, headers } = getApiUrlAndHeaders("/classes/BankFacilitiesSystemCodeSetup/" + id);
    const response = await run(axios.put(url, data, { headers }));
    return response.data;
  };

  return {
    update,
    ...rest,
  };
}

// Hook to remove a bank facilities system code setup
export function useRemoveBankFacilitiesSystemCodeSetup() {
  const { run, ...rest } = useAsync();

  const remove = async (id) => {
    const { url, headers } = getApiUrlAndHeaders("/classes/BankFacilitiesSystemCodeSetup/" + id);
    const response = await run(axios.delete(url, { headers }));
    return response.data;
  };

  return {
    remove,
    ...rest,
  };
}
