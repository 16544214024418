import React from 'react';
import { Form, Input, Row, Col, Card } from 'antd';

const BankFacilitiesSystemCodeSetupFields = ({ data, isEditing, onFieldChange }) => {
  const handleFieldChange = (field, value) => {
    onFieldChange(field, value);
  };

  const renderField = (label, value, type, field) => {
    const displayValue = value || 'N/A';

    return isEditing ? (
      <Input
        defaultValue={value}
        onChange={(e) => handleFieldChange(field, e.target.value)}
        style={{ width: '100%' }}
      />
    ) : (
      displayValue
    );
  };

  return (
    <Card bordered={false} style={{ boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', marginBottom: '24px' }}>
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Segment Value">
              {renderField('Segment Value', data.segmentValue, 'text', 'segmentValue')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Description">
              {renderField('Description', data.description, 'text', 'description')}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default BankFacilitiesSystemCodeSetupFields;
