import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/react";
import reportWebVitals from './reportWebVitals';
import { IntlProvider } from 'react-intl';
import enMessages from "./lang/en.json";
import AuthProvider from './components/AuthProvider';
import SupportChat from './components/SupportChat';
import config from "./config/analytics";
import { ToastContainer } from 'react-toastify';
  // If we're in prod, boot up sentry

if (process?.env?.REACT_APP_ENVIRONMENT === 'production' && process?.env?.REACT_APP_SENTRY_DSN) {
	Sentry.init(config.sentry)

	Sentry.init(config.sentry)
}



ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale="en" messages={enMessages}>
      <AuthProvider>
      {/* <SupportChat
      idengagerchatConfig={config.idengagerchat}
   /> */}
        <App />
        <ToastContainer newestOnTop={true} />
      </AuthProvider>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
