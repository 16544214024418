import React from 'react';
import { Select } from 'antd';
import Flag from 'react-world-flags';

const { Option } = Select;


const countryCodeMap = {
  SAR: 'SA',
  EGP: 'EG',
  USD: 'US',
  EUR: 'EU',
  GBP: 'GB',
  JPY: 'JP',
  CHF: 'CH',
  AED: 'AE',
  CAD: 'CA',
  AUD: 'AU',
  CNY: 'CN',
  INR: 'IN',
  BRL: 'BR',
  RUB: 'RU',
  KRW: 'KR',
  SGD: 'SG'
};

const CurrencySelect = ({ value, onChange, style = {}, currencyOptions }) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      style={{ ...style }}
      optionLabelProp="label"
    >
      {currencyOptions.map(option => (
        <Option key={option.value} value={option.value} label={option.label}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Flag
              code={countryCodeMap[option.value]}
              height="16"
              style={{ marginRight: 8, borderRadius: 2 }}
            />
            <span>{option.label}</span>
          </div>
        </Option>
      ))}
    </Select>
  );
};

export default CurrencySelect;
