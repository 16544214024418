import React from 'react';
import { Form, Input, InputNumber, DatePicker, Select, Row, Col, Card } from 'antd';
import moment from 'moment';
const { Option } = Select;

const LCFields = ({ data, isEditing, onFieldChange }) => {
  const handleFieldChange = (field, value) => {
    onFieldChange(field, value);
  };

  const bankOptions = [
    'ALAHLI',
    'SAMBA',
    'SABB',
    'RIYAD BANK',
    'ARAB BANK',
    'SAUDI FRANSI',
    'Al Rajhi',
    'ALBILAD',
    'ALINMA',
  ];

  const renderField = (label, value, type, field) => {
    const displayValue = value || 'N/A';
    switch (type) {
      case 'number':
        return isEditing ? (
          <InputNumber
            min={0}
            defaultValue={value}
            onChange={(value) => handleFieldChange(field, value)}
            style={{ width: '100%' }}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
        ) : (
          displayValue
        );
      case 'date':
        return isEditing ? (
          <DatePicker
            defaultValue={moment(value)}
            onChange={(date) => handleFieldChange(field, date.toISOString())}
            style={{ width: '100%' }}
          />
        ) : (
          moment(value).isValid() ? moment(value).format('YYYY-MM-DD') : 'Invalid Date'
        );
      case 'select':
        return isEditing ? (
          <Select
            defaultValue={value}
            onChange={(value) => handleFieldChange(field, value)}
            style={{ width: '100%' }}
          >
            {bankOptions.map((bank) => (
              <Option key={bank} value={bank}>
                {bank}
              </Option>
            ))}
          </Select>
        ) : (
          displayValue
        );
      case 'text':
      default:
        return isEditing ? (
          <Input
            defaultValue={value}
            onChange={(e) => handleFieldChange(field, e.target.value)}
            style={{ width: '100%' }}
          />
        ) : (
          displayValue
        );
    }
  };

  return (
    <Card bordered={false} style={{ boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', marginBottom: '24px' }}>
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Company"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Company', data.company, 'text', 'company')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="LC Number"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('LC Number', data.LCNumber, 'text', 'LCNumber')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Vendor"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Vendor', data.vendor, 'text', 'vendor')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Bank"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Bank', data.bank, 'select', 'bank')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Bank ID"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Bank ID', data.bankID ? data.bankID : '', 'text', 'bankID')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="LC Bank Reference"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('LC Bank Reference', data.LCBankReference ? data.LCBankReference : '', 'text', 'LCBankReference')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Issued Date"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Issued Date', data.issuedDate, 'date', 'issuedDate')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Payment Method"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Payment Method', data.paymentMethod, 'text', 'paymentMethod')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Authorized Representative"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Authorized Representative', data.authorizedRepresentative, 'text', 'authorizedRepresentative')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Amount"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Amount', data.amount, 'number', 'amount')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Expiry Date"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Expiry Date', data.expiryDate, 'date', 'expiryDate')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Issuing Bank"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Issuing Bank', data.issuingBank, 'text', 'issuingBank')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Balance Amount"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Balance Amount', data.balanceAmount, 'number', 'balanceAmount')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Margin Amount"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Margin Amount', data.marginAmount, 'number', 'marginAmount')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Margin Tolerance"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Margin Tolerance', data.marginTolerance, 'number', 'marginTolerance')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="VAT"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('VAT', data.VAT, 'number', 'VAT')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Created At"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {moment(data.createdAt).format('YYYY-MM-DD')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Updated At"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {moment(data.updatedAt).format('YYYY-MM-DD')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Beneficiary"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Beneficiary', data.beneficiary, 'text', 'beneficiary')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Terms"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Terms', data.terms, 'text', 'terms')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Bank Charges"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Bank Charges', data.bankCharges, 'number', 'bankCharges')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="LC Bank Facility"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('LC Bank Facility', data.LCBankFacility, 'text', 'LCBankFacility')}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default LCFields;
