// pages/AppSetup/BankAccountSetup.js
import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, message, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getBankAccountSetups, useCreateBankAccountSetup } from '../../hooks/useBankAccountSetup';
import CreateBankAccountSetupForm from '../../components/BankAccountSetup/CreateBankAccountSetupForm';

const BankAccountSetupPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { create, loading: createLoading } = useCreateBankAccountSetup();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getBankAccountSetups();
      setData(result);
    } catch (error) {
      console.error('Error fetching bank account setups:', error);
      message.error('Failed to fetch bank account setups');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCreate = async (values) => {
    try {
      await create(values);
      message.success('Bank Account Setup created successfully');
      setIsModalVisible(false);
      fetchData();
    } catch (error) {
      console.error('Error creating bank account setup:', error);
      message.error('Failed to create bank account setup');
    }
  };

  const handleViewDetails = (record) => {
    navigate(`/setup/bank-account-setup/${record.objectId}`);
  };

const columns = [
  {
    title: 'Bank Code',
    dataIndex: 'bankCode',
    key: 'bankCode',
    sorter: (a, b) => a.bankCode.localeCompare(b.bankCode),
  },
  {
    title: 'Bank ID/Account',
    dataIndex: 'bankIdAccount',
    key: 'bankIdAccount',
  },
  {
    title: 'Bank Group',
    dataIndex: 'bankGroup',
    key: 'bankGroup',
  },
  {
    title: 'Ledger Account',
    dataIndex: 'ledgerAccount',
    key: 'ledgerAccount',
  },
  {
    title: 'Account Status',
    dataIndex: 'accountStatus',
    key: 'accountStatus',
    render: (status) => (status ? 'Active' : 'Inactive'),
  },
  {
    title: 'Bank Charge Account',
    dataIndex: 'bankChargeAccount',
    key: 'bankChargeAccount',
  },
  {
    title: 'Finance Expense Account',
    dataIndex: 'financeExpenseAccount',
    key: 'financeExpenseAccount',
  },
  {
    title: 'LC Bank Facility',
    dataIndex: 'lcBankFacility',
    key: 'lcBankFacility',
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (record) => (
      <Space size="middle">
        <Button type="link" onClick={() => handleViewDetails(record)}>
          View Details
        </Button>
      </Space>
    ),
  },
];

  return (
    <div style={{ padding: '24px', paddingTop: '75px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <h2>Bank Account Setup</h2>
        <Button type="primary" onClick={() => setIsModalVisible(true)}>
          Create New Setup
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey="objectId"
        pagination={{ pageSize: 10 }}
      />
      <Modal
        title="Create New Bank Account Setup"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <CreateBankAccountSetupForm onSubmit={handleCreate} loading={createLoading} />
      </Modal>
    </div>
  );
};

export default BankAccountSetupPage;
