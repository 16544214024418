import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, message, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getLGAccountingSetups, useCreateLGAccountingSetup, useRemoveLGAccountingSetup } from '../../hooks/useLGAccountingSetup';
import CreateLGAccountingSetupForm from '../../components/LGAccountingSetup/CreateLGAccountingSetupForm';

const LGAccountingSetupPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { create, loading: createLoading } = useCreateLGAccountingSetup();
  const { remove, loading: removeLoading } = useRemoveLGAccountingSetup();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getLGAccountingSetups();
      setData(result);
    } catch (error) {
      console.error('Error fetching LG accounting setups:', error);
      message.error('Failed to fetch LG accounting setups');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCreate = async (values) => {
    try {
      const objectId = await create(values);
      message.success('LG Accounting Setup created successfully');
      setIsModalVisible(false);
      // Navigate to the details page of the newly created setup
      navigate(`/setup/lg-accounting-setup/${objectId}`);
    } catch (error) {
      console.error('Error creating LG accounting setup:', error);
      message.error('Failed to create LG accounting setup');
    }
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      message.success('LG Accounting Setup deleted successfully');
      fetchData();
    } catch (error) {
      console.error('Error deleting LG accounting setup:', error);
      message.error('Failed to delete LG accounting setup');
    }
  };

  const handleViewDetails = (record) => {
    navigate(`/setup/lg-accounting-setup/${record.objectId}`);
  };

  const columns = [
    {
      title: 'Oracle Account',
      dataIndex: 'oracleAccount',
      key: 'oracleAccount',
      sorter: (a, b) => (a.oracleAccount || '').localeCompare(b.oracleAccount || ''),
      ellipsis: true,
    },
    {
      title: 'Ledger Account',
      dataIndex: 'ledgerAccount',
      key: 'ledgerAccount',
      sorter: (a, b) => (a.ledgerAccount || '').localeCompare(b.ledgerAccount || ''),
    },
    {
      title: 'Bankors Field',
      dataIndex: 'bankorsField',
      key: 'bankorsField',
      sorter: (a, b) => (a.bankorsField || '').localeCompare(b.bankorsField || ''),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button type="primary" onClick={() => handleViewDetails(record)}>
            View Details
          </Button>
          <Button danger onClick={() => handleDelete(record.objectId)} loading={record.objectId === removeLoading}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: '24px', paddingTop: '75px' }}>
      <div style={{ marginBottom: 16 }}>
        <Button type="primary" onClick={() => setIsModalVisible(true)}>
          Create New LG Accounting Setup
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey="objectId"
        pagination={{ pageSize: 10 }}
      />

      <Modal
        title="Create LG Accounting Setup"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={800}
      >
        <CreateLGAccountingSetupForm onSubmit={handleCreate} loading={createLoading} />
      </Modal>
    </div>
  );
};

export default LGAccountingSetupPage;