// components/BankAccountSetup/CreateBankAccountSetupForm.js
import React from 'react';
import { Form, Input, Button, Checkbox, Row, Col, Select, message } from 'antd';

const bankOptions = [
  'ALAHLI',
  'SAMBA',
  'SABB',
  'RIYAD BANK',
  'ARAB BANK',
  'SAUDI FRANSI',
  'Al Rajhi',
  'ALBILAD',
  'ALINMA',
];

const CreateBankAccountSetupForm = ({ onSubmit, loading }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    try {
      onSubmit(values);
      form.resetFields();
    } catch (error) {
      message.error('Failed to submit form: ' + error.message);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="bankCode" label="Bank Code" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="bankIdAccount" label="Bank ID/Account" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="bankGroup" label="Bank Group" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="ledgerAccount" label="Ledger Account" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="accountStatus" label="Account Status" valuePropName="checked">
            <Checkbox>Active</Checkbox>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="bankChargeAccount" label="Bank Charge Account" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="financeExpenseAccount" label="Finance Expense Account" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="lcBankFacility" label="LC Bank Facility" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateBankAccountSetupForm;
