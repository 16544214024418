import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Button, Spin, message, Space, Descriptions } from 'antd';
import { useGetLGAccountingSetup, useUpdateLGAccountingSetup } from '../../hooks/useLGAccountingSetup';
import EditLGAccountingSetupForm from '../../components/LGAccountingSetup/EditLGAccountingSetupForm';

const LGAccountingSetupDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const { data: setup, loading, error, revalidate } = useGetLGAccountingSetup(id);
  const { update, loading: updateLoading } = useUpdateLGAccountingSetup();

  if (loading) {
    return (
      <div style={{ padding: '24px', paddingTop: '75px' }}>
        <Card>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
            <Spin size="large" tip="Loading LG Accounting Setup details..." />
          </div>
        </Card>
      </div>
    );
  }

  if (error) {
    message.error('Failed to load LG Accounting Setup details');
    return (
      <div style={{ padding: '24px', paddingTop: '75px' }}>
        <Card>
          <div style={{ textAlign: 'center' }}>
            <div style={{ marginBottom: '16px' }}>Error loading details</div>
            <Button onClick={() => navigate('/setup/lg-accounting-setup')}>Back to List</Button>
          </div>
        </Card>
      </div>
    );
  }

  if (!setup) {
    return (
      <div style={{ padding: '24px', paddingTop: '75px' }}>
        <Card>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
            <Spin size="large" tip="Loading LG Accounting Setup details..." />
          </div>
        </Card>
      </div>
    );
  }

  const handleUpdate = async (values) => {
    try {
      await update(id, values);
      message.success('LG Accounting Setup updated successfully');
      setIsEditing(false);
      revalidate();
    } catch (error) {
      console.error('Error updating LG accounting setup:', error);
      message.error('Failed to update LG accounting setup');
    }
  };

  const handleBack = () => {
    navigate('/setup/lg-accounting-setup');
  };

  return (
    <div style={{ padding: '24px', paddingTop: '75px' }}>
      <Card
        title="LG Accounting Setup Details"
        extra={
          <Space>
            <Button onClick={handleBack}>Back to List</Button>
            <Button type="primary" onClick={() => setIsEditing(!isEditing)}>
              {isEditing ? 'Cancel Edit' : 'Edit'}
            </Button>
          </Space>
        }
      >
        {isEditing ? (
          <EditLGAccountingSetupForm
            initialValues={setup}
            onSubmit={handleUpdate}
            loading={updateLoading}
          />
        ) : (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Oracle Account">{setup?.oracleAccount || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label="Ledger Account">{setup?.ledgerAccount || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label="Bankors Field">{setup?.bankorsField || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label="Created At">
              {setup?.createdAt ? new Date(setup.createdAt).toLocaleString() : 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Updated At">
              {setup?.updatedAt ? new Date(setup.updatedAt).toLocaleString() : 'N/A'}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Card>
    </div>
  );
};

export default LGAccountingSetupDetails;