// src/pages/LetterOfGuarantee/LetterOfGuaranteeDetails.js
import React, { useState, useEffect } from 'react';
import { Button, message, Card, Spin, Tabs } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetLetterOfGuarantee, useUpdateLetterOfGuarantee } from '../../hooks/useLetterOfGuarantee';
import LGFields from '../../components/LG/LGFields';
import LGFooterActions from '../../components/LG/LGFooterActions';
import LGLogList from '../../components/LG/LGLogList';
import LGIntegrationTable from '../../components/LG/LGIntegrationTable';
import LGTransactionsTable from '../../components/LG/LGTransactionsTable';

const { TabPane } = Tabs;

const LetterOfGuaranteeDetails = () => {
  const { id } = useParams();
  const { data, loading, error, revalidate } = useGetLetterOfGuarantee(id);
  const { update } = useUpdateLetterOfGuarantee();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [editableData, setEditableData] = useState({});

  useEffect(() => {
    if (data) {
      const { createdAt, updatedAt, ...editableData } = data;  // Exclude createdAt and updatedAt
      setEditableData(editableData);
      console.log('Editable data set:', editableData);
    }
  }, [data]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    message.error('Failed to fetch letter of guarantee details');
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No data found</div>;
  }

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      await update(id, editableData);
      message.success('Letter of Guarantee updated successfully');
      setIsEditing(false);
      revalidate();
    } catch (error) {
      message.error('Failed to update letter of guarantee');
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    const { createdAt, updatedAt, ...editableData } = data;  // Exclude createdAt and updatedAt
    setEditableData(editableData);
  };

  const handleFieldChange = (field, value) => {
    setEditableData({ ...editableData, [field]: value });
  };

  return (
    <div style={{ padding: '24px', paddingTop: '75px' }}>
      <Button onClick={() => navigate(-1)} style={{ marginBottom: 16 }}>
        Back to List
      </Button>
      <Card
        title="Letter of Guarantee Details"
        extra={
          !isEditing ? (
            <Button onClick={handleEdit}>Edit</Button>
          ) : (
            <Button onClick={handleSave} type="primary" style={{ marginRight: 8 }}>
              Save
            </Button>
          )
        }
        bordered={false}
        style={{ boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)' }}
      >
        <LGFields data={isEditing ? editableData : data} isEditing={isEditing} onFieldChange={handleFieldChange} />
        {isEditing && (
          <Button onClick={handleCancelEdit} style={{ marginTop: 16 }}>
            Cancel
          </Button>
        )}
        {!isEditing && (
          <LGFooterActions id={id} data={data} revalidate={revalidate} />
        )}
      </Card>
      <Card bordered={false} style={{ marginTop: 24, boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)' }}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Change History" key="1">
            <LGLogList lcId={id} />
          </TabPane>
          <TabPane tab="Integration" key="2">
            <LGIntegrationTable lcId={id} />
          </TabPane>
          {/* <TabPane tab="Transactions" key="3">
            <LGTransactionsTable lcId={id} />
          </TabPane> */}
        </Tabs>
      </Card>
    </div>
  );
};

export default LetterOfGuaranteeDetails;
