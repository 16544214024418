// src/components/BankFacility/BankFacilityFields.js
import React from 'react';
import { Form, Input, DatePicker, Row, Col, Card, Select } from 'antd';
import moment from 'moment';

const BankFacilityFields = ({ data, isEditing, onFieldChange }) => {
  const handleFieldChange = (field, value) => {
    onFieldChange(field, value);
  };

  const renderField = (label, value, type, field) => {
    const displayValue = value || 'N/A';
    switch (type) {
      case 'date':
        return isEditing ? (
          <DatePicker
            // defaultValue={moment(value)}
            onChange={(date) => handleFieldChange(field, date.toISOString())}
            style={{ width: '100%' }}
          />
        ) : (
          moment(value).isValid() ? moment(value).format('YYYY-MM-DD') : 'Invalid Date'
        );
      case 'select':
        return isEditing ? (
          <Select
            defaultValue={value}
            onChange={(val) => handleFieldChange(field, val)}
            style={{ width: '100%' }}
          >
            <Select.Option value="active">Active</Select.Option>
            <Select.Option value="inactive">Inactive</Select.Option>
            <Select.Option value="pending">Pending</Select.Option>
          </Select>
        ) : (
          displayValue
        );
      case 'number':
        return isEditing ? (
          <Input
            type="number"
            defaultValue={value}
            onChange={(e) => handleFieldChange(field, e.target.value)}
            style={{ width: '100%' }}
          />
        ) : (
          displayValue
        );
      case 'text':
      default:
        return isEditing ? (
          <Input
            defaultValue={value}
            onChange={(e) => handleFieldChange(field, e.target.value)}
            style={{ width: '100%' }}
          />
        ) : (
          displayValue
        );
    }
  };

  return (
    <Card bordered={false} style={{ boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', marginBottom: '24px' }}>
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item 
              label="Facility Description"
              labelCol={{ style: { fontWeight: 'bold' } }}
            >
              {renderField('Facility Description', data.facilityDescription, 'text', 'facilityDescription')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label="Facility Number"
              labelCol={{ style: { fontWeight: 'bold' } }}
            >
              {renderField('Facility Number', data.facilityNumber, 'text', 'facilityNumber')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item 
              label="Facility Status"
              labelCol={{ style: { fontWeight: 'bold' } }}
            >
              {renderField('Facility Status', data.facilityStatus, 'select', 'facilityStatus')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label="Facility Amount"
              labelCol={{ style: { fontWeight: 'bold' } }}
            >
              {renderField('Facility Amount', data.facilityAmount, 'number', 'facilityAmount')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item 
              label="Issue Date"
              labelCol={{ style: { fontWeight: 'bold' } }}
            >
              {renderField('Issue Date', data.issueDate, 'date', 'issueDate')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label="Expiry Date"
              labelCol={{ style: { fontWeight: 'bold' } }}
            >
              {renderField('Expiry Date', data.expiryDate, 'date', 'expiryDate')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item 
              label="Bank"
              labelCol={{ style: { fontWeight: 'bold' } }}
            >
              {renderField('Bank', data.bank, 'text', 'bank')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label="Bank Account/ID"
              labelCol={{ style: { fontWeight: 'bold' } }}
            >
              {renderField('Bank Account/ID', data.bankAccountId, 'text', 'bankAccountId')}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default BankFacilityFields;
