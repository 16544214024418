import React, { useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, message, Row, Col, Select, Checkbox } from 'antd';
import { getBankAccountSetups } from '../../hooks/useBankAccountSetup';

const CreateBankFacilityForm = ({ onSubmit, loading }) => {
  const [form] = Form.useForm();
  const [bankData, setBankData] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [bankAccountOptions, setBankAccountOptions] = useState([]);

  useEffect(() => {
    const fetchBankData = async () => {
      try {
        const data = await getBankAccountSetups();
        setBankData(data);
      } catch (error) {
        message.error('Failed to fetch bank data');
      }
    };
    fetchBankData();
  }, []);

  // Get unique bank groups
  const uniqueBankGroups = [...new Set(bankData.map(item => item.bankGroup))];

  // Update bank account options when bank is selected
  const handleBankChange = (value) => {
    setSelectedBank(value);
    form.setFieldValue('bankAccountId', undefined); // Reset bank account selection
    
    // Filter bank accounts for selected bank
    const filteredAccounts = bankData.filter(item => item.bankGroup === value);
    setBankAccountOptions(filteredAccounts.map(item => ({
      value: item.bankIdAccount,
      label: item.bankIdAccount
    })));
  };

  const onFinish = (values) => {
    try {
      const data = {
        ...values,
        expiryDate: values.expiryDate ? values.expiryDate.toISOString() : undefined,
        issueDate: values.issueDate ? values.issueDate.toISOString() : undefined,
        status: values.status ? 'active' : 'inactive'
      };
      onSubmit(data);
      form.resetFields();
    } catch (error) {
      message.error('Failed to submit form: ' + error.message);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="facilityDescription" label="Facility Description" rules={[{ required: true }]} >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="facilityNumber" label="Facility Number" rules={[{ required: true }]} >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="facilityStatus" label="Facility Status" rules={[{ required: true }]} >
            <Select>
              <Select.Option value="given_to_beneficiary">Given to Beneficiary</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="facilityAmount" label="Facility Amount" rules={[{ required: true }]} >
            <Input type="number" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="status" label="Status" valuePropName="checked" initialValue={true}>
            <Checkbox>Active</Checkbox>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="issueDate" label="Issue Date" rules={[{ required: true }]} >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="expiryDate" label="Expiry Date" rules={[{ required: true }]} >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="bank" label="Bank" rules={[{ required: true }]} >
            <Select
              placeholder="Select Bank"
              onChange={handleBankChange}
            >
              {uniqueBankGroups.map((bank) => (
                <Select.Option key={bank} value={bank}>
                  {bank}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="bankAccountId" label="Bank Account/ID" rules={[{ required: true }]} >
            <Select
              placeholder="Select Bank Account"
              disabled={!selectedBank}
              options={bankAccountOptions}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateBankFacilityForm;
