import React, { useState } from 'react';
import { Form, Input, Button, Select, DatePicker, InputNumber, Steps, message, Row, Col } from 'antd';
import { currencyOptions, CurrencySelect } from '../../constants/currencyOptions';
const { Step } = Steps;
const { Option } = Select;

const statusOptions = [
  { label: 'Open', value: 'Open' },
  { label: 'Closed', value: 'Closed' },
  { label: 'Pending', value: 'Pending' },
  { label: 'Released', value: 'Released' },
];

const bankOptions = [
  'ALAHLI',
  'SAMBA',
  'SABB',
  'RIYAD BANK',
  'ARAB BANK',
  'SAUDI FRANSI',
  'Al Rajhi',
  'ALBILAD',
  'ALINMA',
];

const companyOptions = ['Zoman', 'RK'];

const CreateLGForm = () => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [basicInfo, setBasicInfo] = useState({});
  const [details, setDetails] = useState({});
  const [datesAndMore, setDatesAndMore] = useState({});

  const next = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      if (currentStep === 0) {
        setBasicInfo(values);
      } else if (currentStep === 1) {
        setDetails(values);
      }
      setCurrentStep(currentStep + 1);
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const onFinish = (values) => {
    try {
      setDatesAndMore(values);
      const data = {
        ...basicInfo,
        ...details,
        ...values,
        requestDate: values.requestDate ? values.requestDate.toISOString() : undefined,
        expirationDate: values.expirationDate ? values.expirationDate.toISOString() : undefined,
        type: 'LG',
      };
      const pendingRequests = JSON.parse(localStorage.getItem('pendingRequests')) || [];
      pendingRequests.push(data);
      localStorage.setItem('pendingRequests', JSON.stringify(pendingRequests));
      form.resetFields();
      message.success('Request added to pending requests!');
    } catch (error) {
      message.error('Failed to add request: ' + error.message);
    }
  };

  const steps = [
    {
      title: 'Basic Info',
      content: (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="company" label="Company" rules={[{ required: true }]} >
                <Select>
                  {companyOptions.map(company => (
                    <Option key={company} value={company}>{company}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="LGNumber" label="LG Number" rules={[{ required: true }]} >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="LGBankNumber" label="LG Bank Reference" rules={[{ required: true }]} >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="bank" label="Bank" rules={[{ required: true }]} >
                <Select>
                  {bankOptions.map(bank => (
                    <Option key={bank} value={bank}>{bank}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="bankID" label="Bank ID" rules={[{ required: true }]} >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="originType" label="Origin Type" rules={[{ required: true }]} >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="origin" label="Origin ID" rules={[{ required: true }]} >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="accountType" label="Account Type" rules={[{ required: true }]} >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="account" label="Account ID" rules={[{ required: true }]} >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: 'Details',
      content: (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="status" label="Status" rules={[{ required: true }]} >
                <Select>
                  {statusOptions.map(option => (
                    <Option key={option.value} value={option.value}>{option.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="LGType" label="LG Type" rules={[{ required: true }]} >
                <Select>
                  <Option value="Bid bond">Bid bond</Option>
                  <Option value="Performance bond">Performance bond</Option>
                  <Option value="Advance payment bond">Advance payment bond</Option>
                  <Option value="Retention bond">Retention bond</Option>
                  <Option value="Surety bond">Surety bond</Option>
                  <Option value="Financial bond">Financial bond</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="amount" label="Amount" rules={[{ required: true }]} >
                <InputNumber style={{ width: '100%' }}
                 formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                 parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="agreementNumber" label="Bank Facility" rules={[{ required: true }]} >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="marginAmount" label="Margin Amount" rules={[{ required: true }]} >
                <InputNumber 
                 formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                 parser={value => value.replace(/\$\s?|(,*)/g, '')}                
                style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="marginPercentage" label="Margin %" rules={[{ required: true }]} >
                <InputNumber min={0} max={100} 
                 formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                 parser={value => value.replace(/\$\s?|(,*)/g, '')}                
                style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="bankCharges" label="Bank Charges" rules={[{ required: true }]} >
                <InputNumber 
                 formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                 parser={value => value.replace(/\$\s?|(,*)/g, '')}                
                style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="VAT" label="VAT" rules={[{ required: true }]} >
                <InputNumber 
                 formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                 parser={value => value.replace(/\$\s?|(,*)/g, '')}                
                style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="beneficiary" label="Beneficiary">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="terms" label="Terms">
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: 'Dates & More',
      content: (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="requestDate" label="Issue Date" rules={[{ required: true }]} >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="expirationDate" label="Expiration Date" rules={[{ required: true }]} >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="facilityStatus" label="Facility Status" rules={[{ required: true }]} >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="currency"
                label="Currency"
                rules={[{ required: true, message: 'Please select currency' }]}
              >
                <CurrencySelect currencyOptions={currencyOptions} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="thirdParty" label="Third Party">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="cancellationReason" label="Cancellation Reason">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  return (
    <>
      <Steps current={currentStep}>
        {steps.map((item, index) => (
          <Step key={index} title={item.title} />
        ))}
      </Steps>
      <Form form={form} layout="vertical" onFinish={onFinish} style={{ marginTop: 20 }}>
        {steps[currentStep].content}
        <div className="steps-action" style={{ marginTop: 20 }}>
          {currentStep > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Previous
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

export default CreateLGForm;
