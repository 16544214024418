import axios from "axios";
import React from "react";
import { getApiUrlAndHeaders } from "../utils/api";
import useAsync from "./useAsync";

// Fetch all LG accounting setups
export const getLGAccountingSetups = async () => {
  const { url, headers } = getApiUrlAndHeaders("/classes/LGAccountingSetup");
  const response = await axios.get(url, { headers });
  return response.data.results;
};

// Hook to fetch a single LG accounting setup by ID
export function useGetLGAccountingSetup(id) {
  const async = useAsync();

  const getLGAccountingSetup = async () => {
    const { url, headers } = getApiUrlAndHeaders("/classes/LGAccountingSetup/" + id);
    const response = await axios.get(url, { headers });
    return response.data;
  };

  const revalidate = async () => {
    const setup = await getLGAccountingSetup(id);
    async.setData(setup);
  };

  React.useEffect(() => {
    async.run(getLGAccountingSetup());
  }, [id]);

  return { ...async, revalidate };
}

// Hook to create a new LG accounting setup
export function useCreateLGAccountingSetup() {
  const { run, ...rest } = useAsync();

  const create = async (data) => {
    try {
      const { url, headers } = getApiUrlAndHeaders("/classes/LGAccountingSetup");
      const response = await run(axios.post(url, data, { headers }));
      return response.data.objectId;
    } catch (error) {
      console.error('Error in createLGAccountingSetup:', error);
      throw error;
    }
  };

  return {
    create,
    ...rest,
  };
}

// Hook to update an existing LG accounting setup
export function useUpdateLGAccountingSetup() {
  const { run, ...rest } = useAsync();

  const update = async (id, data) => {
    const { url, headers } = getApiUrlAndHeaders("/classes/LGAccountingSetup/" + id);
    const response = await run(axios.put(url, data, { headers }));
    return response.data;
  };

  return { update, ...rest };
}

// Hook to remove a LG accounting setup
export function useRemoveLGAccountingSetup() {
  const { run, ...rest } = useAsync();

  const remove = async (id) => {
    const { url, headers } = getApiUrlAndHeaders("/classes/LGAccountingSetup/" + id);
    const response = await run(axios.delete(url, { headers }));
    return response.data;
  };

  return { remove, ...rest };
}
