import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, message, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getBankFacilitiesSystemCodeSetups, useCreateBankFacilitiesSystemCodeSetup } from '../../hooks/useBankFacilitiesSystemCodeSetup';
import CreateBankFacilitiesSystemCodeSetupForm from '../../components/BankFacilitiesSystemCodeSetup/CreateBankFacilitiesSystemCodeSetupForm';

const BankFacilitiesSystemCodeSetupPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { create, loading: createLoading } = useCreateBankFacilitiesSystemCodeSetup();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getBankFacilitiesSystemCodeSetups();
      setData(result);
    } catch (error) {
      console.error('Error fetching bank facilities system code setups:', error);
      message.error('Failed to fetch bank facilities system code setups');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCreate = async (values) => {
    try {
      await create(values);
      message.success('Bank Facilities System Code Setup created successfully');
      setIsModalVisible(false);
      fetchData();
    } catch (error) {
      console.error('Error creating bank facilities system code setup:', error);
      message.error('Failed to create bank facilities system code setup');
    }
  };

  const handleViewDetails = (record) => {
    navigate(`/setup/bank-facilities-system-code-setup/${record.objectId}`);
  };

  const columns = [
    {
      title: 'Segment Value',
      dataIndex: 'segmentValue',
      key: 'segmentValue',
      sorter: (a, b) => a.segmentValue.localeCompare(b.segmentValue),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => handleViewDetails(record)}>
            View Details
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: '24px', paddingTop: '75px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <h2>Bank Facilities System Code Setup</h2>
        <Button type="primary" onClick={() => setIsModalVisible(true)}>
          Create New Setup
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey="objectId"
        pagination={{ pageSize: 10 }}
      />
      <Modal
        title="Create New Bank Facilities System Code Setup"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <CreateBankFacilitiesSystemCodeSetupForm onSubmit={handleCreate} loading={createLoading} />
      </Modal>
    </div>
  );
};

export default BankFacilitiesSystemCodeSetupPage;
