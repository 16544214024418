import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, message, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getLoanAccountingSetups, useCreateLoanAccountingSetup, useRemoveLoanAccountingSetup } from '../../hooks/useLoanAccountingSetup';
import CreateLoanAccountingSetupForm from '../../components/LoanAccountingSetup/CreateLoanAccountingSetupForm';

const LoanAccountingSetupPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { create, loading: createLoading } = useCreateLoanAccountingSetup();
  const { remove, loading: removeLoading } = useRemoveLoanAccountingSetup();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getLoanAccountingSetups();
      console.log('Fetched Loan Accounting Setups:', result);
      setData(result);
    } catch (error) {
      console.error('Error fetching Loan accounting setups:', error);
      message.error('Failed to fetch Loan accounting setups');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCreate = async (values) => {
    try {
      const objectId = await create(values);
      message.success('Loan Accounting Setup created successfully');
      setIsModalVisible(false);
      // Navigate to the details page of the newly created setup
      navigate(`/setup/loan-accounting-setup/${objectId}`);
    } catch (error) {
      console.error('Error creating Loan accounting setup:', error);
      message.error('Failed to create Loan accounting setup');
    }
  };

  const handleRemove = async (id) => {
    try {
      await remove(id);
      message.success('Loan Accounting Setup removed successfully');
      fetchData();
    } catch (error) {
      console.error('Error removing Loan accounting setup:', error);
      message.error('Failed to remove Loan accounting setup');
    }
  };

  const columns = [
    {
      title: 'Oracle Account',
      dataIndex: 'oracleAccount',
      key: 'oracleAccount',
      sorter: (a, b) => (a.oracleAccount || '').localeCompare(b.oracleAccount || ''),
      ellipsis: true,
    },
    {
      title: 'Ledger Account',
      dataIndex: 'ledgerAccount',
      key: 'ledgerAccount',
      sorter: (a, b) => (a.ledgerAccount || '').localeCompare(b.ledgerAccount || ''),
    },
    {
      title: 'Bankors Field',
      dataIndex: 'bankorsField',
      key: 'bankorsField',
      sorter: (a, b) => (a.bankorsField || '').localeCompare(b.bankorsField || ''),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button type="primary" onClick={() => navigate(`/setup/loan-accounting-setup/${record.objectId}`)}>View Details</Button>
          <Button danger onClick={() => handleRemove(record.objectId)} loading={removeLoading}>Remove</Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: '24px', paddingTop: '75px' }}>
      <div style={{ marginBottom: 16 }}>
        <Button type="primary" onClick={() => setIsModalVisible(true)}>
          Create New Loan Accounting Setup
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey="id"
        pagination={{ pageSize: 10 }}
      />

      <Modal
        title="Create Loan Accounting Setup"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={800}
      >
        <CreateLoanAccountingSetupForm onSubmit={handleCreate} loading={createLoading} />
      </Modal>
    </div>
  );
};

export default LoanAccountingSetupPage;
