import axios from "axios";
import React from "react";
import { getApiUrlAndHeaders } from "../utils/api";
import useAsync from "./useAsync";

// Fetch all bank account setups
export const getBankAccountSetups = async () => {
  const { url, headers } = getApiUrlAndHeaders("/classes/BankAccountSetup");
  const response = await axios.get(url, { headers });
  return response.data.results;
};

// Hook to fetch a single bank account setup by ID
export function useGetBankAccountSetup(id) {
  const async = useAsync();

  const getBankAccountSetup = async () => {
    const { url, headers } = getApiUrlAndHeaders("/classes/BankAccountSetup/" + id);
    const response = await axios.get(url, { headers });
    return response.data;
  };

  const revalidate = async () => {
    const setup = await getBankAccountSetup(id);
    async.setData(setup);
  };

  React.useEffect(() => {
    async.run(getBankAccountSetup());
  }, [id]);

  return { ...async, revalidate };
}

// Hook to create a new bank account setup
export function useCreateBankAccountSetup() {
  const { run, ...rest } = useAsync();

  const create = async (data) => {
    try {
      const { url, headers } = getApiUrlAndHeaders("/classes/BankAccountSetup");
      const response = await run(axios.post(url, data, { headers }));
      return response.data.objectId;
    } catch (error) {
      console.error('Error in createBankAccountSetup:', error);
      throw error;
    }
  };

  return {
    create,
    ...rest,
  };
}

// Hook to update an existing bank account setup
export function useUpdateBankAccountSetup() {
  const { run, ...rest } = useAsync();

  const update = async (id, data) => {
    const { url, headers } = getApiUrlAndHeaders("/classes/BankAccountSetup/" + id);
    const response = await run(axios.put(url, data, { headers }));
    return response.data;
  };

  return {
    update,
    ...rest,
  };
}

// Hook to remove a bank account setup
export function useRemoveBankAccountSetup() {
  const { run, ...rest } = useAsync();

  const remove = async (id) => {
    const { url, headers } = getApiUrlAndHeaders("/classes/BankAccountSetup/" + id);
    const response = await run(axios.delete(url, { headers }));
    return response.data;
  };

  return {
    remove,
    ...rest,
  };
}
