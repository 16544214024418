import axios from "axios";
import React from "react";
import { getApiUrlAndHeaders } from "../utils/api";
import useAsync from "./useAsync";

// Fetch all bank facilities
export const getBankFacilities = async () => {
  const { url, headers } = getApiUrlAndHeaders("/classes/BankFacility");
  const response = await axios.get(url, { headers });
  return response.data.results;
};

// Hook to fetch a single bank facility by ID
export function useGetBankFacility(id) {
  const async = useAsync();

  const getBankFacility = async () => {
    const { url, headers } = getApiUrlAndHeaders("/classes/BankFacility/" + id);
    const response = await axios.get(url, { headers });
    return response.data;
  };

  const revalidate = async () => {
    const facility = await getBankFacility(id);
    async.setData(facility);
  };

  React.useEffect(() => {
    async.run(getBankFacility());
  }, [id]);

  return { ...async, revalidate };
}

// Hook to create a new bank facility
export function useCreateBankFacility() {
  const { run, ...rest } = useAsync();

  const create = async (data) => {
    try {
      const { url, headers } = getApiUrlAndHeaders("/classes/BankFacility");
      const response = await run(axios.post(url, data, { headers }));
      console.log('API response:', response);
      return response.data.objectId;
    } catch (error) {
      console.error('Error in createBankFacility:', error);
      throw error;
    }
  };

  return {
    create,
    ...rest,
  };
}

// Hook to update an existing bank facility
export function useUpdateBankFacility() {
  const { run, ...rest } = useAsync();

  const update = async (id, data) => {
    const { url, headers } = getApiUrlAndHeaders("/classes/BankFacility/" + id);
    const response = await run(axios.put(url, data, { headers }));
    return response.data;
  };

  return {
    update,
    ...rest,
  };
}

// Hook to remove a bank facility
export function useRemoveBankFacility() {
  const { run, ...rest } = useAsync();

  const remove = async (id) => {
    const { url, headers } = getApiUrlAndHeaders("/classes/BankFacility/" + id);
    const response = await run(axios.delete(url, { headers }));
    return response.data;
  };

  return {
    remove,
    ...rest,
  };
}
