import iCloudLogo from './../assets/images/logo.svg';
import homeIcon from './../icons/home.svg';
import devicesIcon from './../icons/devices-icon.svg';
import rulesIcon from './../icons/rules-icon.svg';
import sessionsIcon from './../icons/sessions-icon.svg';
import usersIcon from './../icons/users-icon.svg';
import objectsIcon from './../icons/objects-icon.svg';
import inAppIcon from './../icons/in-app-messaging-icon.svg';
import pushNotificationsIcon from './../icons/push-notifications-icon.svg';
import chatBotsIcon from './../icons/chat-bots-icon.svg';
import smsIcon from './../icons/sms-icon.svg';
import emailIcon from './../icons/email-icon.svg';
import surveyIcon from './../icons/survey-icon.svg';
import campaignIcon from './../icons/campaign-icon.svg';
import bankAccountIcon from './../icons/bank-account-setup.svg';
import projectSegmentIcon from './../icons/project-setup.svg';
import bankFacilitiesSystemCodeIcon from './../icons/bank-facility-setup-icon.svg';
import colorsIcon from './../icons/colors-icon.svg';
import imagesIcon from './../icons/images-icon.svg';
import labelsIcon from './../icons/labels-icon.svg';
import lgAccountingSetupIcon from './../icons/lg-accounting-setup.svg';
import { Layout, Menu } from 'antd';
import * as ROUTES from './../constants/routes';
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl'
import useGetAppInfo from '../hooks/get_app_info';
import useGetAllClasses from '../hooks/get_all_classes';
import useGetAllObjects from '../hooks/get_all_objects';

function read(obj, nestedFields) {
    for (let field of nestedFields.split(".")) {
        if (!obj && typeof obj !== "object") return obj;
        obj = obj[field];
    }

    return obj;
}

function isAppFeature(appInfo, feature) {
    if (!appInfo) return true;
    return read(appInfo, feature) !== false
}

function doesObjectExist(objects, objectName) {
    if (!objects) return true;
    return !!objects.find(obj => obj.className === objectName);
}

function SideMenu(props) {
    const { Sider } = Layout;
    const { SubMenu } = Menu;
    const location = useLocation();
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [openSubmenuKeys, setOpenSubmenuKeys] = useState(["letter-of-guarantee"]);
    const navigate = useNavigate();
    const { data: appInfo } = useGetAppInfo();
    const { objects } = useGetAllObjects();

    // Handle Current Route Active
    useEffect(() => {
        if (!ROUTES.INTERNAL_LINKS.includes(location.pathname))
            setSelectedKeys([location.pathname]);
    }, [location.pathname]);

    // Handle Current UnCollapsed Sub-Menu
    useEffect(() => {
        if (selectedKeys.includes(ROUTES.DEVICES) || selectedKeys.includes(ROUTES.RULES) ||
            selectedKeys.includes(ROUTES.SESSIONS) || selectedKeys.includes(ROUTES.USERS) ||
            selectedKeys.includes(ROUTES.OBJECTS)) {
            setOpenSubmenuKeys(['backend-sub-menu']);
        }
        else if (selectedKeys.includes(ROUTES.MESSAGING) || selectedKeys.includes(ROUTES.NOTIFICATION) ||
            selectedKeys.includes(ROUTES.CHAT_BOT)) {
            setOpenSubmenuKeys(['engage-sub-menu']);
        }
        else if (selectedKeys.includes(ROUTES.COLORS) || selectedKeys.includes(ROUTES.IMAGES) ||
            selectedKeys.includes(ROUTES.LABELS) || selectedKeys.includes(ROUTES.SETUP_MESSAGES)) {
            setOpenSubmenuKeys(['app-setup-sub-menu']);
        }
        else if (selectedKeys.includes(ROUTES.LOGS) || selectedKeys.includes(ROUTES.EVENTS)) {
            setOpenSubmenuKeys(['app-analytics-sub-menu']);
        }
        else if (selectedKeys.includes(ROUTES.JOBS) || selectedKeys.includes(ROUTES.EVENTS)) {
            setOpenSubmenuKeys(['app-integrations-sub-menu']);
        } else if ( selectedKeys.includes(ROUTES.LetterOfGuaranteePage)
        ) {
            setOpenSubmenuKeys(["letter-of-guarantee"])
        }
    }, [selectedKeys]);

    // Handle Routes Redirects Navigations
    function redirect(route) {
        navigate(route);
    }

    // Handle Sub-Menu Click
    function openSubmenu(key) {
        if (openSubmenuKeys.includes(key)) // Hanlde Sub-Menu Collapse
            setOpenSubmenuKeys([]);
        else   // Hanlde Sub-Menu Un-Collapse
            setOpenSubmenuKeys([key]);
    }


    return (
        <Sider className="side-menu" trigger={null} collapsible collapsed={props.collapsed}>
            <div id="logo" className="logo">
                <img src={iCloudLogo} alt="icloud-logo" />
            </div>
            <Menu className="menu" theme="dark" selectedKeys={selectedKeys} openKeys={openSubmenuKeys} mode="inline">
                <Menu.Item className="menu-item" onClick={e => redirect(ROUTES.HOME)} key={ROUTES.HOME} icon={<img src={homeIcon} alt="home-icon" />}>
                    <FormattedMessage id='sideMenu-home' defaultMessage="Home" />
                </Menu.Item>
                <Menu.Item className="menu-item" onClick={e => redirect(ROUTES.LIST_APPS)} key={ROUTES.LIST_APPS}>
                    <FormattedMessage id="all-apps" defaultMessage="All Apps" />
                </Menu.Item>

                {/* letter-of-guarantee submenu */}
                <SubMenu id="letterofguarantee-sidemenu-item" onTitleClick={e => openSubmenu("letter-of-guarantee")} className="menu-item" key="letter-of-guarantee" title="Letter Of Guarantee">
                {isAppFeature(appInfo, "features.realstate.realstateProject") &&
                        <Menu.Item id="letterofguarantee-all" className="sub-menu-item" onClick={e => redirect(ROUTES.LetterOfGuaranteePage)} key={ROUTES.LetterOfGuaranteePage} icon={<img src={objectsIcon} alt="catalog-icon" />}>
                            <FormattedMessage id='letter-of-guarantee-all' defaultMessage="All" />
                        </Menu.Item>}

                </SubMenu>
                                {/* Loan SubMenu */}
                                <SubMenu onTitleClick={e => openSubmenu("loan")} className="menu-item" key="loan" title="Loan">
                {isAppFeature(appInfo, "features.realstate.loan") &&
                        <Menu.Item id="loan-all" className="sub-menu-item" onClick={e => redirect(ROUTES.LoanPage)} key={ROUTES.LoanPage} icon={<img src={objectsIcon} alt="catalog-icon" />}>
                            <FormattedMessage id='loan-all' defaultMessage="All" />
                        </Menu.Item>}
                </SubMenu>
                {/* LetterOfCredit submenu */}
                <SubMenu onTitleClick={e => openSubmenu("letterofcredit")} className="menu-item" key="letterofcredit" title="LetterOfCredit">
                {isAppFeature(appInfo, "features.realstate.realstateProject") &&
                        <Menu.Item id="letterofguarantee-all" className="sub-menu-item" onClick={e => redirect(ROUTES.LetterOfCreditPage)} key={ROUTES.LetterOfCreditPage} icon={<img src={objectsIcon} alt="catalog-icon" />}>
                            <FormattedMessage id='letter-of-credit-all' defaultMessage="All" />
                        </Menu.Item>}
                </SubMenu>

                {/* Bank Facility SubMenu */}
                <SubMenu onTitleClick={e => openSubmenu("bank-facility")} className="menu-item" key="bank-facility" title="Bank Facility">
                    {isAppFeature(appInfo, "features.realstate.bankFacility") &&
                        <Menu.Item id="bankfacility-all" className="sub-menu-item" onClick={e => redirect(ROUTES.BankFacilityPage)} key={ROUTES.BankFacilityPage} icon={<img src={objectsIcon} alt="bank-facility-icon" />}>
                            <FormattedMessage id='bank-facility-all' defaultMessage="All" />
                        </Menu.Item>}
                </SubMenu>

                <SubMenu onTitleClick={e => openSubmenu("backend-sub-menu")} className="menu-item" key="backend-sub-menu" title="Backend">
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.DEVICES)} key={ROUTES.DEVICES} icon={<img src={devicesIcon} alt="devices-icon" />}>
                        <FormattedMessage id='sideMenu-devices' defaultMessage="Devices" />
                    </Menu.Item>
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.RULES)} key={ROUTES.RULES} icon={<img src={rulesIcon} alt="rules-icon" />}>
                        <FormattedMessage id='sideMenu-rules' defaultMessage="Rules" />
                    </Menu.Item>
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.SESSIONS)} key={ROUTES.SESSIONS} icon={<img src={sessionsIcon} alt="sessions-icon" />}>
                        <FormattedMessage id='sideMenu-sessions' defaultMessage="Sessions" />
                    </Menu.Item>
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.USERS)} key={ROUTES.USERS} icon={<img src={usersIcon} alt="users-icon" />}>
                        <FormattedMessage id='sideMenu-users' defaultMessage="Users" />
                    </Menu.Item>
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.OBJECTS)} key={ROUTES.OBJECTS} icon={<img src={objectsIcon} alt="objects-icon" />}>
                        <FormattedMessage id='sideMenu-objects' defaultMessage="Objects" />
                    </Menu.Item>
                </SubMenu>
                {/* Engage SubMenu */}
                {/* <SubMenu onTitleClick={e => openSubmenu('engage-sub-menu')} className="menu-item" key="engage-sub-menu" title={<FormattedMessage id='sideMenu-engage' defaultMessage="Engage" />}>
                    <Menu.Item id="messaging-menu-item" className="sub-menu-item" onClick={e => redirect(ROUTES.MESSAGING)} key={ROUTES.MESSAGING} icon={<img src={inAppIcon} alt="in-app-messaging-icon" />}>
                        <FormattedMessage id='sideMenu-app-messaging' defaultMessage="In App Messaging" />
                    </Menu.Item>
                    <Menu.Item id="push-notification-menu-item" className="sub-menu-item" onClick={e => redirect(ROUTES.NOTIFICATION)} key={ROUTES.NOTIFICATION} icon={<img src={pushNotificationsIcon} alt="push-notifications-icon" />}>
                        <FormattedMessage id='sideMenu-push-notification' defaultMessage="Push Notification" />
                    </Menu.Item>
                    <Menu.Item id="chat-bot-menu-item" className="sub-menu-item" onClick={e => redirect(ROUTES.CHAT_BOT)} key={ROUTES.CHAT_BOT} icon={<img src={chatBotsIcon} alt="chat-bots-icon" />}>
                        <FormattedMessage id='sideMenu-chat-bots' defaultMessage="Chat Bots" />
                    </Menu.Item>
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.SMS)} key={ROUTES.SMS} icon={<img src={smsIcon} alt="chat-bots-icon" />}>
                        <FormattedMessage id='sms' defaultMessage="SMS" />
                    </Menu.Item>
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.EMAIL)} key={ROUTES.EMAIL} icon={<img src={emailIcon} alt="chat-bots-icon" />}>
                        <FormattedMessage id='email' defaultMessage="Email" />
                    </Menu.Item>
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.SURVEY)} key={ROUTES.SURVEY} icon={<img src={surveyIcon} alt="chat-bots-icon" />}>
                        <FormattedMessage id='survey' defaultMessage="Survey" />
                    </Menu.Item>
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.CAMPAIGN)} key={ROUTES.CAMPAIGN} icon={<img src={campaignIcon} alt="chat-bots-icon" />}>
                        <FormattedMessage id='campaign' defaultMessage="Campaign" />
                    </Menu.Item>
                </SubMenu> */}

                {/* App Config SubMenu */}
                <SubMenu id="app-setup" onTitleClick={e => openSubmenu("app-setup-sub-menu")} className="menu-item" key="app-setup-sub-menu" title="App Setup">
                    {isAppFeature(appInfo, "features.bankAccountSetup") && (
                        <Menu.Item 
                        id="bank-account-setup" 
                        className="sub-menu-item" 
                        onClick={e => redirect(ROUTES.BANK_ACCOUNT_SETUP)} 
                        key={ROUTES.BANK_ACCOUNT_SETUP} 
                        icon={<img src={bankAccountIcon} alt="bank-account-icon" />}
                        >
                        <FormattedMessage id='sideMenu-bank-account-setup' defaultMessage="Bank Account Setup" />
                        </Menu.Item>
                    )}

                    {isAppFeature(appInfo, "features.projectSegmentSetup") && (
                        <Menu.Item 
                            id="project-segment-setup" 
                            className="sub-menu-item" 
                            onClick={e => redirect(ROUTES.PROJECT_SEGMENT_SETUP)} 
                            key={ROUTES.PROJECT_SEGMENT_SETUP} 
                            icon={<img src={projectSegmentIcon} alt="project-segment-icon" />}
                        >
                            <FormattedMessage id='sideMenu-project-segment-setup' defaultMessage="Project Segment Setup" />
                        </Menu.Item>
                        )}

                    {isAppFeature(appInfo, "features.bankFacilitiesSystemCodeSetup") && (
                        <Menu.Item 
                            id="bank-facilities-system-code-setup" 
                            className="sub-menu-item" 
                            onClick={e => redirect(ROUTES.BANK_FACILITIES_SYSTEM_CODE_SETUP)} 
                            key={ROUTES.BANK_FACILITIES_SYSTEM_CODE_SETUP} 
                            icon={<img src={bankFacilitiesSystemCodeIcon} alt="bank-facilities-system-code-icon" />}
                        >
                            <FormattedMessage id='sideMenu-bank-facilities-system-code-setup' defaultMessage="Bank Facilities System Code Setup" />
                        </Menu.Item>
                    )}

                    {isAppFeature(appInfo, "features.lgAccountingSetup") && (
                        <Menu.Item 
                            id="lg-accounting-setup" 
                            className="sub-menu-item" 
                            onClick={e => redirect(ROUTES.LG_ACCOUNTING_SETUP)} 
                            key={ROUTES.LG_ACCOUNTING_SETUP} 
                            icon={<img src={lgAccountingSetupIcon} alt="lg-accounting-setup-icon" />}
                        >
                            <FormattedMessage id='sideMenu-lg-accounting-setup' defaultMessage="LG Accounting Setup" />
                        </Menu.Item>
                    )}

                    {isAppFeature(appInfo, "features.lcAccountingSetup") && (
                        <Menu.Item 
                            id="lc-accounting-setup" 
                            className="sub-menu-item" 
                            onClick={e => redirect(ROUTES.LC_ACCOUNTING_SETUP)} 
                            key={ROUTES.LC_ACCOUNTING_SETUP} 
                            icon={<img src={lgAccountingSetupIcon} alt="lc-accounting-setup-icon" />}
                        >
                            <FormattedMessage id='sideMenu-lc-accounting-setup' defaultMessage="LC Accounting Setup" />
                        </Menu.Item>
                    )}

                    {isAppFeature(appInfo, "features.loanAccountingSetup") && (
                        <Menu.Item 
                            id="loan-accounting-setup" 
                            className="sub-menu-item" 
                            onClick={e => redirect(ROUTES.LOAN_ACCOUNTING_SETUP)} 
                            key={ROUTES.LOAN_ACCOUNTING_SETUP} 
                            icon={<img src={lgAccountingSetupIcon} alt="loan-accounting-setup-icon" />}
                        >
                            <FormattedMessage id='sideMenu-loan-accounting-setup' defaultMessage="Loan Accounting Setup" />
                        </Menu.Item>
                    )}

                </SubMenu>

                {/* <SubMenu onTitleClick={e => openSubmenu("app-analytics-sub-menu")} className="menu-item" key="app-analytics-sub-menu" title="App Analytics">
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.EVENTS)} key={ROUTES.EVENTS} icon={<img src={colorsIcon} alt="events-icon" />}>
                        <FormattedMessage id='sideMenu-events' defaultMessage="EVENTS" />
                    </Menu.Item>
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.LOGS)} key={ROUTES.IMAGES} icon={<img src={imagesIcon} alt="logs-icon" />}>
                        <FormattedMessage id='sideMenu-logs' defaultMessage="Logs" />
                    </Menu.Item>

                </SubMenu>
                <SubMenu onTitleClick={e => openSubmenu("app-integrations-sub-menu")} className="menu-item" key="app-integrations-sub-menu" title="App Integrations">
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.JOBS)} key={ROUTES.JOBS} icon={<img src={colorsIcon} alt="jobs-icon" />}>
                        <FormattedMessage id='sideMenu-jobs' defaultMessage="JOBS" />
                    </Menu.Item>
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.HOOKS)} key={ROUTES.HOOKS} icon={<img src={imagesIcon} alt="hooks-icon" />}>
                        <FormattedMessage id='sideMenu-hooks' defaultMessage="Hooks" />
                    </Menu.Item>
                </SubMenu>

                <Menu.Item className="menu-item" onClick={e => redirect(ROUTES.LEADS)} key={ROUTES.LEADS}>
                    <FormattedMessage id='leads' defaultMessage="Leads" />
                </Menu.Item> */}
            </Menu>
        </Sider>
    );
}

export default SideMenu;
