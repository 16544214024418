import axios from "axios";
import React from "react";
import { getApiUrlAndHeaders } from "../utils/api";
import useAsync from "./useAsync";

// Fetch all Loan accounting setups
export const getLoanAccountingSetups = async () => {
  const { url, headers } = getApiUrlAndHeaders("/classes/LoanAccountingSetup");
  const response = await axios.get(url, { headers });
  return response.data.results;
};

// Hook to fetch a single Loan accounting setup by ID
export function useGetLoanAccountingSetup(id) {
  const async = useAsync();

  const getLoanAccountingSetup = async () => {
    const { url, headers } = getApiUrlAndHeaders("/classes/LoanAccountingSetup/" + id);
    const response = await axios.get(url, { headers });
    return response.data;
  };

  const revalidate = async () => {
    const setup = await getLoanAccountingSetup();
    async.setData(setup);
  };

  React.useEffect(() => {
    async.run(getLoanAccountingSetup());
  }, [id]);

  return { ...async, revalidate };
}

// Hook to create a new Loan accounting setup
export function useCreateLoanAccountingSetup() {
  const { run, ...rest } = useAsync();

  const create = async (data) => {
    try {
      const { url, headers } = getApiUrlAndHeaders("/classes/LoanAccountingSetup");
      const response = await run(axios.post(url, data, { headers }));
      return response.data.objectId;
    } catch (error) {
      console.error('Error in createLoanAccountingSetup:', error);
      throw error;
    }
  };

  return {
    create,
    ...rest,
  };
}

// Hook to update an existing Loan accounting setup
export function useUpdateLoanAccountingSetup() {
  const { run, ...rest } = useAsync();

  const update = async (id, data) => {
    const { url, headers } = getApiUrlAndHeaders("/classes/LoanAccountingSetup/" + id);
    await run(axios.put(url, data, { headers }));
  };

  return {
    update,
    ...rest,
  };
}

// Hook to remove a Loan accounting setup
export function useRemoveLoanAccountingSetup() {
  const { run, ...rest } = useAsync();

  const remove = async (id) => {
    const { url, headers } = getApiUrlAndHeaders("/classes/LoanAccountingSetup/" + id);
    await run(axios.delete(url, { headers }));
  };

  return {
    remove,
    ...rest,
  };
}
