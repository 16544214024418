import React, { useState } from 'react';
import { Button, Modal, message, Space, DatePicker, Input, Checkbox, InputNumber } from 'antd';
import { useUpdateLoan } from '../../hooks/useLoan';
import moment from 'moment';
import LoanModalContent from './LoanModalContent';

const LoanFooterActions = ({ id, data, revalidate }) => {
  const { update } = useUpdateLoan();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [actionType, setActionType] = useState('');
  const [newAmount, setNewAmount] = useState(null);
  const [newDate, setNewDate] = useState(null);
  const [bankCharges, setBankCharges] = useState(null);
  const [rePaymentAmount, setRepaymentAmount] = useState(0);
  const [includeFinanceExpense, setIncludeFinanceExpense] = useState(false);
  const [includeVAT, setIncludeVAT] = useState(false);
  const [includeBankCharges, setIncludeBankCharges] = useState(false);
  const [bankChargeAmount, setBankChargeAmount] = useState(0);
  const [includeTaxes, setIncludeTaxes] = useState(false);

  const handleAction = (type) => {
    setActionType(type);
    if (type === 'increaseAmount' || type === 'reduceAmount') {
      setNewAmount(data.amount);
    } else if (type === 'extendDate' || type === 'renewLoan') {
      setNewDate(moment(data.finalDueDate));
    }
    setBankCharges(null);
    setIsModalVisible(true);
  };

  const calculateTotalTaxableCharges = () => {
    if (bankCharges !== null) {
      const charges = parseFloat(bankCharges);
      return (charges + (charges * 0.15)).toString();
    }
    return '0';
  };

  const handleModalOk = async () => {
    try {
      setIsModalVisible(false);
      let updatedData = {};
      let displayData = {};

      if (actionType === 'repayment' && rePaymentAmount > 0) {
        if (rePaymentAmount > data.totalAmount) {
          message.error('Repayment amount cannot be greater than total amount');
          return;
        }

        const financeExpense = includeFinanceExpense ? (data.effectiveMonthlyInterestRate / 100) * rePaymentAmount : 0;
        const vat = includeVAT ? 0.15 * (rePaymentAmount + financeExpense) : 0;
        const totalRepayment = rePaymentAmount + financeExpense + vat + (includeBankCharges ? bankChargeAmount : 0);

        updatedData = {
          id,
          rePaymentAmount: rePaymentAmount,
          type: 'Loan Statement',
          action: 'repayment'
        };

        displayData = {
          ...updatedData,
          financeExpense: financeExpense,
          vat: vat,
          bankCharges: bankChargeAmount,
          totalRepayment: totalRepayment
        };

        const pendingRequests = JSON.parse(localStorage.getItem('pendingRequests')) || [];
        pendingRequests.push(displayData);
        localStorage.setItem('pendingRequests', JSON.stringify(pendingRequests));

        window.dispatchEvent(new Event('storage'));
        message.success('Repayment request saved. Please confirm from the notification icon.');
        return;
      } else if (actionType === 'extendDate' && newDate !== null) {
        updatedData = {
          finalDueDate: newDate.toISOString(),
          bankCharges: includeTaxes ? calculateTotalTaxableCharges() : bankCharges,
          type: 'Loan Statement',
          action: 'extend date'
        };

        const pendingRequests = JSON.parse(localStorage.getItem('pendingRequests')) || [];
        pendingRequests.push({ id, ...updatedData });
        localStorage.setItem('pendingRequests', JSON.stringify(pendingRequests));

        window.dispatchEvent(new Event('storage'));
        message.success('Extend date request saved. Please confirm from the notification icon.');
        return;
      } else if (actionType === 'increaseAmount' && newAmount !== null) {
        const newTotalAmount = data.totalAmount + newAmount;
        updatedData = {
          amount: newTotalAmount,
          bankCharges: includeTaxes ? calculateTotalTaxableCharges() : bankCharges,
          type: 'Loan Statement',
          action: 'increase amount'
        };

        const pendingRequests = JSON.parse(localStorage.getItem('pendingRequests')) || [];
        pendingRequests.push({ id, ...updatedData });
        localStorage.setItem('pendingRequests', JSON.stringify(pendingRequests));

        window.dispatchEvent(new Event('storage'));
        message.success(`Increase amount request saved. New total amount: ${newTotalAmount}. Bank charges: ${includeTaxes ? calculateTotalTaxableCharges() : bankCharges}. Please confirm from the notification icon.`);
        return;
      }

      if (Object.keys(updatedData).length > 0) {
        await update(id, updatedData);
        message.success(`Loan ${actionType.replace(/([A-Z])/g, ' $1').toLowerCase()} successfully`);
        revalidate();
      } else {
        message.warning('No changes detected');
      }
    } catch (error) {
      message.error(`Failed to ${actionType.replace(/([A-Z])/g, ' $1').toLowerCase()} loan`);
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div style={{ marginTop: 16 }}>
        <Space wrap>
          <Button type="primary" onClick={() => handleAction('extendDate')}>
            Extend Date
          </Button>
          <Button type="primary" onClick={() => handleAction('increaseAmount')}>
            Increase Amount
          </Button>
          <Button type="danger" onClick={() => handleAction('cancel')}>
            Cancel
          </Button>
          <Button type="primary" onClick={() => handleAction('reduceAmount')}>
            Reduce Amount
          </Button>
          <Button type="primary" onClick={() => handleAction('transferLoan')}>
            Transfer Loan
          </Button>
          <Button type="primary" onClick={() => handleAction('releaseLoan')}>
            Release Loan
          </Button>
          <Button type="primary" onClick={() => handleAction('amendLoanTerms')}>
            Amend Loan Terms
          </Button>
          <Button type="primary" onClick={() => handleAction('updateBeneficiary')}>
            Update Beneficiary
          </Button>
          <Button type="primary" onClick={() => handleAction('renewLoan')}>
            Renew Loan
          </Button>
          <Button type="primary" onClick={() => handleAction('repayment')}>
            Repayment
          </Button>
        </Space>
      </div>
      <Modal
        title={`Perform Action: ${actionType.replace(/([A-Z])/g, ' $1')}`}
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        {actionType === 'repayment' ? (
          <>
            <InputNumber
              placeholder="Enter repayment amount"
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}              
              value={rePaymentAmount}
              onChange={setRepaymentAmount}
              style={{ width: '100%', marginBottom: '10px' }}
            />
            <Checkbox
              checked={includeFinanceExpense}
              onChange={(e) => setIncludeFinanceExpense(e.target.checked)}
            >
              Include Finance Expense
            </Checkbox>
            {includeFinanceExpense && (
              <p>
                Finance Expense: {(data.effectiveMonthlyInterestRate / 100) * rePaymentAmount}
              </p>
            )}
            <Checkbox
              checked={includeVAT}
              onChange={(e) => setIncludeVAT(e.target.checked)}
            >
              Include VAT
            </Checkbox>
            {includeVAT && (
              <p>
                VAT: {0.15 * (rePaymentAmount + (includeFinanceExpense ? (data.effectiveMonthlyInterestRate / 100) * rePaymentAmount : 0))}
              </p>
            )}
            <Checkbox
              checked={includeBankCharges}
              onChange={(e) => setIncludeBankCharges(e.target.checked)}
            >
              Include Bank Charges
            </Checkbox>
            {includeBankCharges && (
              <>
                <InputNumber
                  placeholder="Enter bank charges"
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}                  
                  value={bankChargeAmount}
                  onChange={setBankChargeAmount}
                  style={{ width: '100%', marginBottom: '10px' }}
                />
                <p>
                  Bank Charges: {bankChargeAmount}
                </p>
              </>
            )}
            <p>
              Total Repayment: {rePaymentAmount + (includeFinanceExpense ? (data.effectiveMonthlyInterestRate / 100) * rePaymentAmount : 0) + (includeVAT ? 0.15 * (rePaymentAmount + (includeFinanceExpense ? (data.effectiveMonthlyInterestRate / 100) * rePaymentAmount : 0)) : 0) + (includeBankCharges ? bankChargeAmount : 0)}
            </p>
          </>
        ) : actionType === 'extendDate' || actionType === 'renewLoan' ? (
          <>
            <DatePicker
              onChange={(date) => setNewDate(date)}
              style={{ width: '100%', marginBottom: '10px' }}
            />
            <InputNumber
              placeholder="Enter bank charges"
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              value={bankCharges}
              onChange={(value) => setBankCharges(value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />
            {bankCharges !== null && bankCharges !== '' && (
              <div style={{ marginTop: 16 }}>
                <Checkbox
                  checked={includeTaxes}
                  onChange={(e) => setIncludeTaxes(e.target.checked)}
                >
                  Add Taxes (15%)
                </Checkbox>
                {includeTaxes && (
                  <p>Total with Taxes: {parseFloat(calculateTotalTaxableCharges()).toFixed(2)}</p>
                )}
              </div>
            )}
          </>
        ) : actionType === 'increaseAmount' ? (
          <>
            <p>Current Amount: {data.totalAmount}</p>
            <InputNumber
              placeholder="Enter amount to increase"
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              value={newAmount}
              onChange={(value) => setNewAmount(value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />
            <InputNumber
              placeholder="Enter bank charges"
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              value={bankCharges}
              onChange={(value) => setBankCharges(value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />
            {bankCharges !== null && bankCharges !== '' && (
              <div style={{ marginTop: 16 }}>
                <Checkbox
                  checked={includeTaxes}
                  onChange={(e) => setIncludeTaxes(e.target.checked)}
                >
                  Add Taxes (15%)
                </Checkbox>
                {includeTaxes && (
                  <p>Total taxable bank charges: {parseFloat(calculateTotalTaxableCharges()).toFixed(2)}</p>
                )}
              </div>
            )}
            <p style={{ marginTop: '10px' }}>New Total Amount will be: {data.totalAmount + (newAmount || 0)}</p>
          </>
        ) : (
          <LoanModalContent
            actionType={actionType}
            newAmount={newAmount}
            setNewAmount={setNewAmount}
            newDate={newDate}
            setNewDate={setNewDate}
            data={data}
            bankCharges={bankCharges}
            setBankCharges={setBankCharges}
          />
        )}
      </Modal>
    </>
  );
};

export default LoanFooterActions;
