import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, DatePicker, InputNumber, Steps, Row, Col, message } from 'antd';
import moment from 'moment';
import { getBankFacilities } from '../../hooks/useBankFacility';
import { getProjectSegmentSetups } from '../../hooks/useProjectSegmentSetup';
import { getBankFacilitiesSystemCodeSetups } from '../../hooks/useBankFacilitiesSystemCodeSetup';
import { getBankAccountSetups } from '../../hooks/useBankAccountSetup';
const { Step } = Steps;
const { Option } = Select;

const loanTypeOptions = ['Short-Term', 'Long-Term'];
const shortTermOptions = ['LC refinance', 'Short term'];
const shortTermSubOptions = ['ST', 'Salary', 'ST Mobilization'];
const longTermOptions = ['Long-term', 'LT Mobilization'];
const companyOptions = ['Zoman', 'RK'];
const loanStatusOptions = ['Active', 'Pending', 'Completed', 'Rejected']

const CreateLoanForm = ({ onSubmit, loading }) => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [loanType, setLoanType] = useState(null);
  const [shortTermType, setShortTermType] = useState(null);
  const [bankFacilities, setBankFacilities] = useState([]);
  const [projectSegments, setProjectSegments] = useState([]);
  const [systemCodes, setSystemCodes] = useState([]);
  const [bankAccountSetups, setBankAccountSetups] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);

  useEffect(() => {
    const fetchBankFacilities = async () => {
      try {
        const facilities = await getBankFacilities();
        setBankFacilities(facilities);
      } catch (error) {
        console.error('Error fetching bank facilities:', error);
        message.error('Failed to load bank facilities');
      }
    };
    fetchBankFacilities();
  }, []);

  useEffect(() => {
    const fetchProjectSegments = async () => {
      try {
        const segments = await getProjectSegmentSetups();
        setProjectSegments(segments);
      } catch (error) {
        console.error('Error fetching project segments:', error);
        message.error('Failed to load project segments');
      }
    };
    fetchProjectSegments();
  }, []);

  useEffect(() => {
    const fetchSystemCodes = async () => {
      try {
        const codes = await getBankFacilitiesSystemCodeSetups();
        setSystemCodes(codes);
      } catch (error) {
        console.error('Error fetching system codes:', error);
        message.error('Failed to load system codes');
      }
    };
    fetchSystemCodes();
  }, []);

  useEffect(() => {
    const fetchBankAccountSetups = async () => {
      try {
        const setups = await getBankAccountSetups();
        setBankAccountSetups(setups);
      } catch (error) {
        console.error('Error fetching bank account setups:', error);
        message.error('Failed to load bank account setups');
      }
    };
    fetchBankAccountSetups();
  }, []);

  const next = () => {
    form.validateFields().then(values => {
      setFormData({ ...formData, ...values });
      setCurrentStep(currentStep + 1);
    }).catch(info => {
      console.log('Validate Failed:', info);
    });
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleFinish = (values) => {
    const combinedValues = {
      ...formData,
      ...values,
      loanIssueDate: formData.loanIssueDate ? formData.loanIssueDate.toISOString() : undefined,
      firstDueDate: formData.firstDueDate ? formData.firstDueDate.toISOString() : undefined,
      finalDueDate: values.finalDueDate ? values.finalDueDate.toISOString() : undefined,
      type: 'Loan'
    };
    const pendingRequests = JSON.parse(localStorage.getItem('pendingRequests')) || [];
    localStorage.setItem('pendingRequests', JSON.stringify([...pendingRequests, combinedValues]));
    window.dispatchEvent(new Event('storage'));

    message.info('Your loan request has been added to pending requests. Please check the notifications.');
    onSubmit(combinedValues);
  };

  const handleLoanTypeChange = (value) => {
    setLoanType(value);
    form.setFieldsValue({ shortTermType: undefined, shortTermSubType: undefined, longTermType: undefined });
  };

  const handleShortTermTypeChange = (value) => {
    setShortTermType(value);
    form.setFieldsValue({ shortTermSubType: undefined });
  };

  const handleAnnualInterestRateChange = (value) => {
    if (value) {
      const monthlyRate = parseFloat((value / 12).toFixed(4));
      form.setFieldsValue({ effectiveMonthlyInterestRate: monthlyRate });
    } else {
      form.setFieldsValue({ effectiveMonthlyInterestRate: undefined });
    }
  };

  const handleBankChargesChange = (value) => {
    if (value) {
      // Convert bank charges to string before storing
      form.setFieldsValue({ bankCharges: value.toString() });
      const vat = value * 0.15; // Calculate VAT as 15% of bank charges
      form.setFieldsValue({ VAT: vat });
    } else {
      form.setFieldsValue({ bankCharges: undefined, VAT: undefined });
    }
  };

  const steps = [
    {
      title: 'Basic Info',
      content: (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="company" label="Company" rules={[{ required: true }]} >
                <Select>
                  {companyOptions.map(company => (
                    <Option key={company} value={company}>{company}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="systemCode" label="System Code" rules={[{ required: true }]} >
                <Select placeholder="Select System Code">
                  {systemCodes.map(code => (
                    <Option key={code.objectId} value={code.segmentValue}>
                      {code.segmentValue}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="bank" label="Bank" rules={[{ required: true }]} >
                <Select onChange={(value) => setSelectedBank(value)}>
                  {[...new Set(bankAccountSetups.map(setup => setup.bankGroup))].map(bank => (
                    <Option key={bank} value={bank}>{bank}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="bankID" label="Bank Account/ID" rules={[{ required: true }]} >
                <Select disabled={!selectedBank}>
                  {bankAccountSetups
                    .filter(setup => setup.bankGroup === selectedBank)
                    .map(setup => (
                      <Option key={setup.objectId} value={setup.bankIdAccount}>
                        {setup.bankIdAccount}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="englishDescription" label="English Description" rules={[{ required: true }]} >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="arabicDescription" label="Arabic Description" rules={[{ required: true }]} >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="loanIssueDate" label="Loan Issue Date" rules={[{ required: true }]} >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="loanReference" label="Loan Bank Reference" rules={[{ required: true }]} >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="loanType" label="Loan Type" rules={[{ required: true }]} >
            <Select onChange={handleLoanTypeChange}>
              {loanTypeOptions.map(type => (
                <Option key={type} value={type}>{type}</Option>
              ))}
            </Select>
          </Form.Item>
          {loanType === 'Short-Term' && (
            <Form.Item name="shortTermType" label="Short Term Type" rules={[{ required: true }]} >
              <Select onChange={handleShortTermTypeChange}>
                {shortTermOptions.map(type => (
                  <Option key={type} value={type}>{type}</Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {loanType === 'Short-Term' && shortTermType === 'Short term' && (
            <Form.Item name="shortTermSubType" label="Short Term Sub-Type" rules={[{ required: true }]} >
              <Select>
                {shortTermSubOptions.map(type => (
                  <Option key={type} value={type}>{type}</Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {loanType === 'Long-Term' && (
            <Form.Item name="longTermType" label="Long Term Type" rules={[{ required: true }]} >
              <Select>
                {longTermOptions.map(type => (
                  <Option key={type} value={type}>{type}</Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </>
      ),
    },
    {
      title: 'Additional Info',
      content: (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="effectiveAnnualInterestRate"
                label="Effective Annual Interest Rate %"
                rules={[{ required: true, type: 'number', max: 100, message: 'Please enter a valid percentage (0-100)' }]}

              >
                <InputNumber
                  min={0}
                  max={100}
                  formatter={value => `${value}%`}
                  parser={value => value.replace('%', '')}
                  style={{ width: '100%' }}
                  onChange={handleAnnualInterestRateChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="saborRate"
                label="Sabor Rate %"
                rules={[{ required: true, type: 'number', max: 100, message: 'Please enter a valid percentage (0-100)' }]}

              >
                <InputNumber
                  min={0}
                  max={100}
                  formatter={value => `${value}%`}
                  parser={value => value.replace('%', '')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="bankFacility" label="Bank Facility" rules={[{ required: true }]} >
                <Select>
                  {bankFacilities.map(facility => (
                    <Option key={facility.objectId} value={facility.objectId}>
                      {facility.facilityDescription}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="finalDueDate" label="Final Due Date" rules={[{ required: true }]} >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="project" label="Project" rules={[{ required: true }]} >
                <Select>
                  {projectSegments.map(project => (
                    <Option key={project.objectId} value={project.objectId}>
                      {project.description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="numberOfYears"
                label="Number of Years"
                rules={[{ required: true, type: 'number', min: 0, message: 'Please enter a valid number of years' }]}

              >
                <InputNumber
                  min={0}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="numberOfPaymentsPerYear" label="Number of Payments Per Year" rules={[{ required: true }]} >
                <InputNumber
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="rePaymentAmount" label="Re-Payment Amount">
                <InputNumber
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="effectiveMonthlyInterestRate"
                label="Effective Monthly Interest Rate %"
                tooltip="Automatically calculated as Annual Rate / 12"
                rules={[{ required: true }]}
              >
                <InputNumber
                  disabled
                  style={{ width: '100%' }}
                  precision={4}
                  formatter={value => `${value}%`}
                  parser={value => value.replace('%', '')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="totalAmount" label="Total Amount" rules={[{ required: true }]} >
                <InputNumber
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="bankCharges" label="Bank Charges" rules={[{ required: true }]} >
                <Input
                  style={{ width: '100%' }}
                  onChange={(e) => handleBankChargesChange(parseFloat(e.target.value) || 0)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="loanStatus"
                label="Loan Status"
                rules={[{ required: true }]}
              >
                <Select placeholder="Select loan status">
                  {loanStatusOptions.map(status => (
                    <Option key={status} value={status}>{status}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="VAT"
                label="VAT (15% of Bank Charges)"
                rules={[{ required: true }]}
                tooltip="Automatically calculated as 15% of Bank Charges"
              >
                <InputNumber
                  disabled
                  style={{ width: '100%' }}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  return (
    <>
      <Steps current={currentStep}>
        {steps.map((item, index) => (
          <Step key={index} title={item.title} />
        ))}
      </Steps>
      <Form form={form} layout="vertical" onFinish={handleFinish} style={{ marginTop: 20 }}>
        {steps[currentStep].content}
        <div className="steps-action" style={{ marginTop: 20 }}>
          {currentStep > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Previous
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button type="primary" htmlType="submit" loading={loading}>
              {loading ? 'Creating...' : 'Submit'}
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

export default CreateLoanForm;
