// src/components/LoanModalContent.js
import React, { useState } from 'react';
import { InputNumber, DatePicker, Input } from 'antd';
import moment from 'moment';

const LoanModalContent = ({ actionType, newAmount, setNewAmount, newDate, setNewDate, data }) => {
  const [userEnteredAmount, setUserEnteredAmount] = useState(0);

  const handleAmountChange = (value) => {
    setUserEnteredAmount(value);
    if (actionType === 'increaseAmount') {
      setNewAmount(value);
    } else if (actionType === 'reduceAmount') {
      setNewAmount(data.amount - value);
    }
  };

  const renderContent = () => {
    switch (actionType) {
      case 'increaseAmount':
      case 'reduceAmount':
        return (
          <div>
            <p>Current Amount: {data.totalAmount}</p>
            <InputNumber
              min={0}
              defaultValue={0}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}              
              onChange={handleAmountChange}
              style={{ width: '100%' }}
              placeholder="Enter amount to increase"
            />
            {userEnteredAmount > 0 && (
              <p>New Total Amount will be: {data.totalAmount + userEnteredAmount}</p>
            )}
          </div>
        );
      case 'extendDate':
      case 'renewLoan':
        return (
          <DatePicker
            defaultValue={moment(newDate)}
            onChange={(date) => setNewDate(date.toISOString())}
            style={{ width: '100%' }}
          />
        );
      case 'updateBeneficiary':
        return (
          <Input
            defaultValue={data.beneficiary || ''}
            onChange={(e) => setNewDate(e.target.value)}
            style={{ width: '100%' }}
          />
        );
      case 'amendLoanTerms':
        return (
          <Input.TextArea
            defaultValue={data.terms || ''}
            onChange={(e) => setNewDate(e.target.value)}
            style={{ width: '100%' }}
          />
        );
      case 'transferLoan':
        // Implement transfer loan specific logic here
        return null;
      case 'releaseLoan':
        // Implement release loan specific logic here
        return null;
      case 'cancel':
        return <p>Are you sure you want to cancel this Loan?</p>;
      default:
        return null;
    }
  };

  return (
    <div>
      {renderContent()}
    </div>
  );
};

export default LoanModalContent;
