import axios from "axios";
import React from "react";
import { getApiUrlAndHeaders } from "../utils/api";
import useAsync from "./useAsync";

// Fetch all LC accounting setups
export const getLCAccountingSetups = async () => {
  const { url, headers } = getApiUrlAndHeaders("/classes/LCAccountingSetup");
  const response = await axios.get(url, { headers });
  return response.data.results;
};

// Hook to fetch a single LC accounting setup by ID
export function useGetLCAccountingSetup(id) {
  const async = useAsync();

  const getLCAccountingSetup = async () => {
    const { url, headers } = getApiUrlAndHeaders("/classes/LCAccountingSetup/" + id);
    const response = await axios.get(url, { headers });
    return response.data;
  };

  const revalidate = async () => {
    const setup = await getLCAccountingSetup(id);
    async.setData(setup);
  };

  React.useEffect(() => {
    async.run(getLCAccountingSetup());
  }, [id]);

  return { ...async, revalidate };
}

// Hook to create a new LC accounting setup
export function useCreateLCAccountingSetup() {
  const { run, ...rest } = useAsync();

  const create = async (data) => {
    try {
      const { url, headers } = getApiUrlAndHeaders("/classes/LCAccountingSetup");
      const response = await run(axios.post(url, data, { headers }));
      return response.data.objectId;
    } catch (error) {
      console.error('Error in createLCAccountingSetup:', error);
      throw error;
    }
  };

  return {
    create,
    ...rest,
  };
}

// Hook to update an existing LC accounting setup
export function useUpdateLCAccountingSetup() {
  const { run, ...rest } = useAsync();

  const update = async (id, data) => {
    const { url, headers } = getApiUrlAndHeaders("/classes/LCAccountingSetup/" + id);
    const response = await run(axios.put(url, data, { headers }));
    return response.data;
  };

  return { update, ...rest };
}

// Hook to remove a LC accounting setup
export function useRemoveLCAccountingSetup() {
  const { run, ...rest } = useAsync();

  const remove = async (id) => {
    const { url, headers } = getApiUrlAndHeaders("/classes/LCAccountingSetup/" + id);
    const response = await run(axios.delete(url, { headers }));
    return response.data;
  };

  return { remove, ...rest };
}
